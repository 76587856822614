import { ProductsService } from './../../products.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  username = JSON.parse(localStorage.getItem('token')).username;
  products: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.products);
  displayedColumns: string[] = ['name', 'category', 'subcategory', 'actionImages', 'actionAttributes', 'actionEdit', 'actionDelete'];

  form: FormGroup;
  totalCount = 0;
  currentPage = 1;
  pageSize = 10;
  productName = '';
  categoryName = '';
  subcategoryName = '';
  sortBy = 'name';
  direction = 'asc';

  isInProgress = false;

  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;

  file: FileUploadModel;

  csvValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  uploaded = false;

  constructor(private productsService: ProductsService,
    private router: Router,
    private spinner: NgxSpinnerService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.bindProducts();

    this.form = this.fb.group({
      productName: ''
    });
  }

  doFilter(event) {
    this.productName = event.target.value;
    this.bindProducts();
  }

  doFilterCategory(event) {
    this.categoryName = event.target.value;
    this.bindProducts();
  }

  doFilterSubcategory(event) {
    this.subcategoryName = event.target.value;
    this.bindProducts();
  }

  doSorting(event) {
    console.log(event);
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindProducts();
  }



  navigateToProductAttributes(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    // this.router.navigate(["/seller/product-attributes"], navigationExtras);
    window.open("/seller/product-attributes/"+ pid, '_blank');
  }


  navigateToProductStock(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    // this.router.navigate(["/seller/stocks"], navigationExtras);
    window.open("/seller/stocks/" + pid, "_blank");
  }


    navigateToProductImages(pid: number) {
      // const navigationExtras: NavigationExtras = {
      //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
      // };
      // this.router.navigate(["/seller/product-images"], navigationExtras);
      window.open("/seller/product-images/" + pid, "_bank");
    }

   navigateToEditProduct(pid: number) {
    this.router.navigate(["/seller/edit-product/" + pid]);
  }

  bindProducts() {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    this.productsService.getByUsername(username, this.productName,
      this.categoryName, this.subcategoryName,
      this.currentPage - 1, this.pageSize,
      this.sortBy, this.direction).subscribe(res => {
        this.products = res.products;

        this.totalCount = res.totalItems;
        this.dataSource = new MatTableDataSource<any>(this.products);
        this.spinner.hide();
      }, error => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
        this.spinner.hide();
      });
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindProducts();
  }

  deleteProduct(id: number) {
    const deleted = confirm('Are you sure you want to delete this product?');
    if (deleted) {
      this.spinner.show();
      this.productsService.deleteProduct(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', 'Product deleted successfully.', 'success');
          this.bindProducts();
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, error => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
        this.spinner.hide();
      });
    }
  }

  onClickStockFile() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.csvValidations.uploadAttempted = true;
      const file = fileUpload.files[0];
      /*       if (!file.name.endsWith('.jpg') &&
              !file.name.endsWith('.png') &&
              !file.name.endsWith('.JPG') &&
              !file.name.endsWith('.PNG')) {
              Swal.fire('Error', 'Only .jpg,.JPG,.png,.PNG files allowed for image upload. ' + file.name, 'error');
              return;
            } */

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (!file.name.endsWith('.csv')) {
        this.csvValidations.validType = false;
      }

      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      // console.log(formData);
      this.file = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile(formData);
    };
    fileUpload.click();
  }

  private uploadFile(formData: FormData) {
    this.productsService.uploadStockFile(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
          this.uploaded = true;
        }
        // this.fileName = 'Seller/Product/' + JSON.parse(localStorage.getItem('token')).username +
        //   '/' + this.activatedRoute.snapshot.params.id + '/' + this.file.data.name;
      },
      (err) => {
        this.error = err;
        console.log('Error:', err);
      }
    );
  }

}
