import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Stock, BankDetails } from './models';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {

  constructor(private http: HttpClient) { }

  // adds bank details
  addBankDetails(bankDetails: BankDetails): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/BankDetails/AddBankDetails';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<boolean>(url, bankDetails, {headers});
  }

  // updates the bank details
  updateBankDetails(bankDetails: BankDetails): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/BankDetails/UpdateBankDetails';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, bankDetails, {headers});
  }

  // gets the bank details of all sellers
  getAllBankDetails(): Observable<BankDetails[]> {
    const url = BaseApiService.baseApiUrl + 'api/BankDetails/GetAllBankDetails';

    return this.http.get<BankDetails[]>(url);
  }

  // gets the bank details of a seller
  getBankDetails(username: string): Observable<BankDetails> {
    const url = BaseApiService.baseApiUrl + 'api/BankDetails/GetBankDetailsBySeller?username=' + username;

    return this.http.get<BankDetails>(url);
  }
}
