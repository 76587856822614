import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { SeasonalDiscount } from './models';

@Injectable({
  providedIn: 'root'
})
export class SeasonalDiscountService {

  constructor(private http: HttpClient) { }

  getSeasonalDiscount(id: number): Observable<SeasonalDiscount> {
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/GetSeasonalDiscount/' + id;

    return this.http.get<SeasonalDiscount>(url);
  }

  getSeasonalDiscounts(): Observable<SeasonalDiscount[]> {
    const sellerUsername = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/GetSeasonalDiscounts?sellerUsername=' + sellerUsername;

    return this.http.get<SeasonalDiscount[]>(url);
  }
  getSeasonalDiscountByCoupon(couponCode: string, productID: number): Observable<SeasonalDiscount[]> {
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/GetSeasonalDiscountByCoupon?couponCode=' + couponCode +
    '&productID=' + productID;

    return this.http.get<SeasonalDiscount[]>(url);
  }
  addSeasonalDiscount(seasonalDiscount: SeasonalDiscount): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/AddSeasonalDiscount';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<boolean>(url, seasonalDiscount, { headers });
  }
  updateSellerDiscount(seasonalDiscount: SeasonalDiscount): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/UpdateSeasonalDiscount';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, seasonalDiscount, { headers });
  }
  deleteSeasonalDiscount(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SeasonalDiscount/DeleteSeasonalDiscount/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, { headers });
  }
}
