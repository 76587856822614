import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PaymentOrder, User } from '../models';
import { OrdersService } from '../orders.service';
import { SellerPaymentService } from '../seller-payment.service';
import { UsersService } from '../users.service';
declare var Razorpay: any;

@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.scss']
})
export class PaynowComponent implements OnInit {
  amount = 0;
  user: User;

  constructor(private usersService: UsersService,
              private ordersService: OrdersService,
              private sellerPaymentService: SellerPaymentService,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.bindSellerPayment();
  }

  bindSellerPayment() {
    this.activatedRoute.queryParams.subscribe(qParams => {
      this.spinner.show();
      this.sellerPaymentService.getSellerPayment(qParams['q']).subscribe(res => {
        this.spinner.hide();
        this.amount = res.amount;
        this.bindUser(qParams['q']);
      }, err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please reload the page and try again...', 'error');
      });
    });
  }

  bindUser(username) {
    this.spinner.show();
    this.usersService.getUserByUsername(username).subscribe(res => {
      this.spinner.hide();
      this.user = res;
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  payNow() {
    const paymentOrder: PaymentOrder = {
      amount: this.amount,
      username: JSON.parse(localStorage.getItem('token')).username,
      status: 'Payment Started'
    };
    this.ordersService.addPaymentOrder(paymentOrder).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const razorPaymentKey = res.razorPaymentKey;
        this.spinner.show();

        this.ordersService.createRazorPaymentOrder(res.orderID, 'INR', this.amount).subscribe(razorRes => {
          this.spinner.hide();
          const options = {
            key: razorPaymentKey,
            amount: this.amount * 100,
            currency: 'INR',
            name: this.user.name,
            description: 'Ecommerce Transaction',
            image: 'https://api.jhelumcart.com/SiteSettings/Logo.png',
            order_id: razorRes.attributes.id,
            handler: (response => {
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);

              this.spinner.show();

              this.ordersService.verifyPayment(response.razorpay_order_id, response.razorpay_payment_id,
                response.razorpay_signature).subscribe(verifyRes => {
                  this.spinner.hide();
                  if (verifyRes === true) {
                    paymentOrder.status = 'Payment Successful.';
                    paymentOrder.id = res.orderID;
                    this.spinner.show();
                    this.ordersService.updatePaymentOrder(paymentOrder).subscribe(updatePaymentOrderRes => {
                      this.spinner.hide();
                      if (updatePaymentOrderRes) {

                        Swal.fire('Success', 'Payment done successfully.', 'success');
                      } else {
                        Swal.fire('Error', 'Payment unsuccessful. Please try again...', 'error');
                      }
                    });
                  } else {
                    Swal.fire('Error', 'Payment not verified. It might be a hack!', 'error');
                  }
                });
            }),
            prefill: {
              name: this.user.name,
              email: this.user.email,
              contact: this.user.mobile
            },
            notes: {
              address: this.user.address
            },
            theme: {
              color: '#F37254'
            }
          };

          const rzp1 = new Razorpay(options);
          rzp1.open();
        });
      }
    });
  }

  public loadScript() {
    let node = document.createElement('script');
    node.src = 'https://checkout.razorpay.com/v1/checkout.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
