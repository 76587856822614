import { Component, OnInit } from '@angular/core';
import { Color, Stock } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from 'src/app/stock.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ColorsService } from 'src/app/colors.service';

@Component({
  selector: 'app-edit-stock',
  templateUrl: './edit-stock.component.html',
  styleUrls: ['./edit-stock.component.scss']
})
export class EditStockComponent implements OnInit {

  stock: Stock;
  form: FormGroup;
  productId: any;
  colors: Color[] = [];
  constructor(private stockService: StockService,
              private router: Router,
              private colorsService: ColorsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
                this.getColors();
              }

  ngOnInit(): void {
    this.bindStock();
  }

  bindStock() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe( params => {
      // this.productId = Number(atob(decodeURIComponent(params["q"])));
      this.productId = Number(this.activatedRoute.snapshot.params.id);

      this.stockService.getById(this.productId).subscribe(res => {
        this.stock = res;
        if (res != null) {
          this.form.patchValue({
            stock: this.stock.physicalStock,
            color: this.stock.color,
            size: this.stock.size,
            weight: this.stock.weight,
            brand: this.stock.brand
          });
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
    });


  }


  getColors() {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.colorsService.getColors().subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.colors = res;

    }, err => {
      this.spinner.hide();
    });
  }


  createForm() {
    this.form = this.fb.group({
      stock: ['', Validators.required],
      color: null,
      size: null,
      weight: '',
      brand: ''
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveStock() {
    this.spinner.show();
    this.stock.physicalStock = this.form.value.stock;
    this.stock.color = this.form.value.color;
    this.stock.size = this.form.value.size;
    this.stock.weight = this.form.value.weight;
    this.stock.brand = this.form.value.brand;

    this.stockService.updateStock(this.stock).subscribe(res => {
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.router.navigate(['/seller/products']);
      } else {
        Swal.fire('Error', res.message, 'error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}
