import { BaseApiService } from 'src/app/base-api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from './models';
import { map } from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';
import { isatty } from 'tty';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  /**
   * getAll
   */
  public getAll(): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetAll';

    return this.http.get<any[]>(url);
  }

  public getByUsername(username: string, productName: string, 
    categoryName: string, subcategoryName: string,
    pageNumber: number, pageSize: number,
    sortBy: string, direction: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetByUsername?username=' + username +
      "&productName=" + productName + "&categoryName=" + categoryName +
      "&subcategoryName=" + subcategoryName + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize +
      "&sortBy=" + sortBy + "&direction=" + direction;

    return this.http.get<any>(url);
  }

  /**
   * getBySubcategory
   */
  public getBySubcategory(subcategoryId: number): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetBySubcategory/' + subcategoryId;

    return this.http.get<any[]>(url);
  }

  /**
   * getById
   */
  public getById(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/Get/' + id;

    return this.http.get<any>(url);
  }

  public getPincodeAvailability(fromPincode: string, toPincode: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetPincodeAvailability?fromPincode=' +
      fromPincode + '&toPincode=' + toPincode;

    return this.http.get<any>(url);
  }

  getFilteredProductsForCategory(categoryId: number, sortBy: number, minPrice: number,
    maxPrice: number, color: string, size: string,
    pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetFilteredProductsForCategory?categoryId=' +
      categoryId + '&sortBy=' + sortBy + '&minPrice=' + minPrice + '&maxPrice=' + maxPrice +
      '&color=' + color + '&size=' + size + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }

  getPriceRangeByCategory(categoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetPriceRangeByCategory?categoryId=' + categoryId;

    return this.http.get<any>(url);
  }

  getColorsByCategory(categoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetColorsByCategory?categoryId=' + categoryId;

    return this.http.get<any>(url);
  }


  getSizesByCategory(categoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetSizesByCategory?categoryId=' + categoryId;

    return this.http.get<any>(url);
  }





  getFilteredProductsForSubCategory(subcategoryId: number, sortBy: number, minPrice: number,
    maxPrice: number, color: string, size: string,
    pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetFilteredProductsForSubCategory?subCategoryId=' +
      subcategoryId + '&sortBy=' + sortBy + '&minPrice=' + minPrice + '&maxPrice=' + maxPrice +
      '&color=' + color + '&size=' + size + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }

  getPriceRangeBySubCategory(subcategoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetPriceRangeBySubCategory?subCategoryId=' + subcategoryId;

    return this.http.get<any>(url);
  }



  getColorsBySubCategory(subcategoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetColorsBySubCategory?subCategoryId=' + subcategoryId;

    return this.http.get<any>(url);
  }

  getSizesBySubCategory(subcategoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetSizesBySubCategory?subCategoryId=' + subcategoryId;

    return this.http.get<any>(url);
  }

  getColorsBySearch(search: string, location: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetColorsBySearch?search=' + search +
      '&location=' + location;

    return this.http.get<any>(url);
  }

  getSizesBySearch(search: String, location: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetSizesBySearch?search=' + search +
      '&location=' + location;

    return this.http.get<any>(url);
  }
  getWeightsBySearch(search: String, location: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetWeightsBySearch?search=' + search +
      '&location=' + location;

    return this.http.get<any>(url);
  }
  getBrandsBySearch(search: String, location: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetBrandsBySearch?search=' + search +
      '&location=' + location;

    return this.http.get<any>(url);
  }

  getPriceRangeBySearch(search: string, location: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetPriceRangeBySearch?search=' + search +
      '&location=' + location;

    return this.http.get<any>(url);
  }

  getProductsBySearch(location: string, search: string,
    sortBy: number, minPrice: number, maxPrice: number, color: string, size: string,
    pageSize: number, pageNumber: number): Observable<any> {

    var url = BaseApiService.baseApiUrl + "api/Product/GetProductsBySearch?location=" + location + "&search=" +
      search + "&sortBy=" + sortBy +
      "&minPrice=" + minPrice + "&maxPrice=" + maxPrice + "&color=" + color +
      "&size=" + size + "&pageSize=" + pageSize +
      "&pageNumber=" + pageNumber;


    return this.http.get<any>(url);
  }

  /**
   * addProduct
   */
  public addProduct(product: Product): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<any>(url, product, { headers });
  }

  /**
   * updateProduct
   */
  public updateProduct(product): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, product, { headers });
  }

  public toggleProductActive(id: number, isActive: boolean): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/ToggleProductActive?id=' + id +
    '&isActive=' + isActive;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, null, { headers });
  }

  /**
   * deleteProduct
   */
  public deleteProduct(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, { headers });
  }

  /**
   * getAutocompleteList
   */
  public getAutocompleteList(query: string): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetAutoCompleteList?search=' + query;

    return this.http.get<any[]>(url);

  }

  public getAutocompleteListForSeller(username: string, query: string): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetAutoCompleteListForSeller?username=' + username +'&search=' + query;

    return this.http.get<any[]>(url);

  }

  getProductsBySearchForSeller(username: string, search: string,
    sortBy: number, minPrice: number, maxPrice: number, color: string, size: string,
    pageSize: number, pageNumber: number): Observable<any> {

    const url = BaseApiService.baseApiUrl + 'api/Product/GetProductsBySearchForSeller?username=' + username +
    '&search=' + search + '&sortBy=' + sortBy +
    '&minPrice=' + minPrice + '&maxPrice=' + maxPrice + '&color=' + color +
    '&size=' + size + '&pageSize=' + pageSize +
    '&pageNumber=' + pageNumber;


    return this.http.get<any>(url);
}

  //Rate product
  rateProduct(productId: number, rating: number, review: string, updateReview: boolean): Observable<any> {
    var url = BaseApiService.baseApiUrl + "api/Product/RateProduct";

    let token = JSON.parse(localStorage.getItem('token'));
    let username: string = token.username;

    var data = {
      productId: productId,
      username: username,
      rating: rating,
      review: review,
      updateReview: updateReview
    };



    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers: headers });
  }

  //Gets all products by username and category
  getProductsByUsernameAndCategory(username: string, categoryId: number,
    sortBy: number, minPrice: number, maxPrice: number, color: string, size: string,
    pageSize: number, pageNumber: number): Observable<any> {

    var url = BaseApiService.baseApiUrl + "api/Product/GetProductsByUsernameAndCategory?username=" +
      username + "&categoryId=" + categoryId + "&sortBy=" + sortBy +
      "&minPrice=" + minPrice + "&maxPrice=" + maxPrice + "&color=" + color +
      "&size=" + size + "&pageSize=" + pageSize +
      "&pageNumber=" + pageNumber;


    return this.http.get<any>(url);
  }



  getColorsByCategoryAndUsername(categoryId: number, username: string) {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetColorsByCategoryAndUsername?categoryId=' +
      categoryId + '&username=' + username;

    return this.http.get<any>(url);
  }

  getSizesByCategoryAndUsername(categoryId: number, username: string) {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetSizesByCategoryAndUsername?categoryId=' +
      categoryId + '&username=' + username;

    return this.http.get<any>(url);
  }

  getPriceRangeByCategoryAndUsername(categoryId: number, username: string) {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetPriceRangeByCategoryAndUsername?categoryId=' +
      categoryId + '&username=' + username;

    return this.http.get<any>(url);
  }

  //Gets ratings and reviews of a product
  getProductRatingsReviews(productId: number, pageNumber: number): Observable<any> {

    const url = BaseApiService.baseApiUrl + "api/Product/GetProductRatingsReviews?productId=" + productId +
      "&pageNumber=" + pageNumber;


    return this.http.get<any>(url);
  }

  getCategoryAndSubcategoriesBySearch(search: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetCategoryAndSubcategoriesBySearch?search=' +
      search;

    return this.http.get<any>(url);
  }

  getCategoryAndSubcategoriesByCategoryId(categoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetCategoryAndSubcategoriesByCategoryId?categoryId=' +
      categoryId;

    return this.http.get<any>(url);
  }

  getCategoryAndSubcategoriesBySubcategoryId(subcategoryId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Product/GetCategoryAndSubcategoriesBySubcategoryId?subcategoryId=' +
      subcategoryId;

    return this.http.get<any>(url);
  }

  // upload product image
  uploadProductImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Product/UploadProductImage';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    }
    let token = JSON.parse(localStorage.getItem('token'));
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // upload stock file
  uploadStockFile(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Product/UploadStockFile';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    }
    let token = JSON.parse(localStorage.getItem('token'));
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  downloadProductReport(username: string, productName: string,
    categoryName: string, subcategoryName: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadProductReport?username=' + username +
      '&productName=' + productName +
      '&categoryName=' + categoryName + '&subcategoryName=' + subcategoryName;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadProductReportForSeller(username: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadProductReportForSeller?username=' + username;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
}
