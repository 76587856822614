import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrdersService } from 'src/app/orders.service';
import { multi } from './data';

@Component({
  selector: 'app-seller-dashboard',
  templateUrl: './seller-dashboard.component.html',
  styleUrls: ['./seller-dashboard.component.scss']
})
export class SellerDashboardComponent implements OnInit {
  todaySales = 0;
  monthlySales = 0;
  yearlySales = 0;
  
  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor(private ordersService: OrdersService,
              private spinner: NgxSpinnerService) { 
          Object.assign(this, { multi });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  
  ngOnInit(): void {
    this.bindSales();
  }

  bindSales() {
    this.spinner.show()
    this.ordersService.getSalesForSeller().subscribe(res => {
      this.spinner.hide();
      this.todaySales = res.todaySales;
      this.monthlySales = res.monthlySales;
      this.yearlySales = res.yearlySales;
    }, err => {
      this.spinner.hide();
    });
  }

}

