import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { SellerPincodeSlab } from './models';

@Injectable({
  providedIn: 'root'
})
export class SellerPincodeSlabsService {

  constructor(private http: HttpClient) { }

  public getSlabs(pincodeId: Number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincodeSlab/GetSlabs?pincodeId=' + pincodeId;

    return this.http.get<any>(url);
  }

  public getSlab(id: number): Observable<SellerPincodeSlab> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincodeSlab/GetSlab/' + id;

    return this.http.get<SellerPincodeSlab>(url);
  }

  public addSlab(shippingPincodeSlab: SellerPincodeSlab): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincodeSlab/AddSlab';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, shippingPincodeSlab, {headers});
  }

  public updateSlab(shippingPincodeSlab: SellerPincodeSlab): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincodeSlab/UpdateSlab';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, shippingPincodeSlab, {headers});
  }

  public deleteSlab(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincodeSlab/DeleteSlab/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }
}
