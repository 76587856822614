import { BaseApiService } from 'src/app/base-api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product, SellerPayment } from './models';
import { map } from 'rxjs/operators';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SellerPaymentService {

  constructor(private http: HttpClient) {

   }

   public getSellerPayment(username: string): Observable<SellerPayment> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPayment/GetSellerPayment?username=' + username;

    return this.http.get<SellerPayment>(url);
  }

  public addSellerPayment(sellerPayment: SellerPayment): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/GetSellerPayment/AddSellerPayment';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<any>(url, sellerPayment, { headers });
  }

  public updateSellerPayment(sellerPayment: SellerPayment): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPayment/UpdateSellerPayment';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<boolean>(url, sellerPayment, { headers });
  }
}
