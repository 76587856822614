import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { ProductDiscount } from './models';

@Injectable({
  providedIn: 'root'
})
export class ProductDiscountService {

  constructor(private http: HttpClient) { }

  getProductDiscount(id: number): Observable<ProductDiscount> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/GetProductDiscount/' + id;

    return this.http.get<ProductDiscount>(url);
  }

  getProductDiscounts(id: number): Observable<ProductDiscount[]> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/GetProductDiscounts/' + id;

    return this.http.get<ProductDiscount[]>(url);
  }
  getProductDiscountsForUser(id: number, username: string): Observable<ProductDiscount[]> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/GetProductDiscountsForUser?id=' + id +
    '&username=' + username;

    return this.http.get<ProductDiscount[]>(url);
  }
  addProductDiscount(productDiscount: ProductDiscount): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/AddProductDiscount';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<boolean>(url, productDiscount, { headers });
  }
  updateProductDiscount(productDiscount: ProductDiscount): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/UpdateProductDiscount';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, productDiscount, { headers });
  }
  deleteProductDiscount(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductDiscount/DeleteProductDiscount/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, { headers });
  }
}
