import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiService } from './../../base-api.service';
import { OrdersService } from './../../orders.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Order, OrderHistory, OrderDetails, User } from 'src/app/models';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  statusForm: FormGroup;
  commentsForm: FormGroup;
  logisticForm: FormGroup;
  dispatchForm: FormGroup;
  baseApiUrl = BaseApiService.baseApiUrl;
  percentComplete = 0;
  isInProgress = false;
  isOrderNotPending = true;
  users: User[] = [];
  order: Order = null;
  orderHistories: OrderHistory[];
  orderDetails: OrderDetails[];
  orderSub: Subscription;
  orderHistoriesSub: Subscription;
  orderDetailsSub: Subscription;
  saveStatusSub: Subscription = null;
  saveCommentsSub: Subscription = null;
  confirmOrderSub: Subscription = null;
  id: any;
  constructor(private fbComments: FormBuilder, private fbStatus: FormBuilder,
              private fbLogistics: FormBuilder, private usersService: UsersService,
              private fbDispatch: FormBuilder,
              private ordersService: OrdersService, private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService, private router: Router,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.statusForm = this.fbStatus.group({
      status: ['', Validators.required]
    });
    this.commentsForm = this.fbComments.group({
      comments: ['', Validators.required],
      date: ['', Validators.required]
    });

    this.logisticForm = this.fbLogistics.group({
      logisticPartner: ['', Validators.required],
      jurisdiction: ['']
    });

    this.dispatchForm = this.fbDispatch.group({
      dispatchCode: ['', Validators.required]
    });

    this.bindOrderDetails();
  }

  bindOrderDetails() {
    this.spinner.show();

    this.activatedRoute.queryParams.subscribe(params => {
      // this.id = Number(atob(decodeURIComponent(params["q"])));
      this.id = Number(this.activatedRoute.snapshot.params.id);

      this.orderSub = this.ordersService.getOrder(this.id).subscribe(res => {
        this.order = res;
        this.isOrderNotPending = res.orderStatus !== 'Pending';
        this.ordersService.getOrderDetails(this.id).subscribe(resOrderDetails => {
          this.orderDetails = resOrderDetails;
          console.log(resOrderDetails);
          this.ordersService.getOrderHistories(this.id).subscribe(resOrderHistories => {
            this.orderHistories = resOrderHistories;
            this.spinner.hide();
          });
        });
      });

    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  changeStatus() {
    this.spinner.show();
    this.saveStatusSub = this.ordersService.updateOrderStatus(this.id, this.statusForm.value.status).subscribe(res => {
      if (res.added) {
        alert('Status updated successfully.');
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
      this.bindOrderDetails();
    }, error => {
      this.spinner.hide();
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.router.navigate(['/sign-in']);
        }
      }
    });
  }

  addComments() {
    this.spinner.show();
    this.saveCommentsSub = this.ordersService.addOrderComments(this.id,
      this.datePipe.transform(this.commentsForm.value.date, 'MM/dd/yyyy'),
      this.commentsForm.value.comments).subscribe(res => {
        if (res.added) {
          alert('Comments added successfully.');
        } else {
          alert('Error occured. Please try again...');
        }
        this.spinner.hide();
        this.bindOrderDetails();
      }, error => {
        this.spinner.hide();
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate(['/sign-in']);
          }
        }
      });
  }

  confirmOrder() {
    // this.spinner.show();
    // this.confirmOrderSub = this.ordersService.confirmOrder(this.id).subscribe(res => {
    //   if (res.added) {
    //     alert('Order confirmed successfully.');
    //   } else {
    //     alert('Error occured. Please try again...');
    //   }
    //   this.spinner.hide();
    //   this.bindOrderDetails();
    // }, error => {
    //   this.spinner.hide();
    //   if (error instanceof HttpErrorResponse) {
    //     if (error.status === 401) {
    //       this.router.navigate(['/sign-in']);
    //     }
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.saveCommentsSub != null) {
      this.saveCommentsSub.unsubscribe();
    }
    if (this.saveStatusSub != null) {
      this.saveStatusSub.unsubscribe();
    }
    if (this.confirmOrderSub != null) {
      this.confirmOrderSub.unsubscribe();
    }
    if (this.orderSub != null) {
      this.orderSub.unsubscribe();
    }
    if (this.orderHistoriesSub != null) {
      this.orderHistoriesSub.unsubscribe();
    }
    if (this.orderDetailsSub != null) {
      this.orderDetailsSub.unsubscribe();
    }
  }

  getLogisticPartners() {
    this.spinner.show();
    this.usersService.getLogisticPartnersByJurisdiction(this.logisticForm.value.jurisdiction).subscribe(res => {
      this.users = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  assignOrderToLogisticPartner() {

    this.spinner.show();
    this.ordersService.assignOrderToLogisticPartner(this.logisticForm.value.logisticPartner,
      this.id).subscribe(res => {
        this.spinner.hide();
        alert(res.message);
      }, err => {
        this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/sign-in']);
          } else {
            alert('Error occured. Please try again...');
          }
        } else {
          alert('Error occured. Please try again...');
        }

      });

  }

  downloadBill() {
    this.spinner.show();
    this.isInProgress = true;
    this.ordersService.downloadOrderBill(this.id).subscribe(res => {
      this.spinner.hide();
      switch (res.type) {
        case HttpEventType.DownloadProgress:
          this.isInProgress = true;
          this.percentComplete = Math.round((res.loaded / res.total) * 100);
          break;
        case HttpEventType.Response:
          this.isInProgress = false;
          this.percentComplete = 0;
          const downloadedFile = new Blob([res.body]);
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = 'Order_' + this.activatedRoute.snapshot.params.id + '.pdf';
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          break;
      }
    });
  }

  dispatchOrder() {
    this.spinner.show();
    this.ordersService.confirmOrder(this.id,
      this.dispatchForm.value.dispatchCode).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', res.message, 'success');
          this.bindOrderDetails();
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
  }

  getProductImage(product, color) {
    return product.productImages.filter(p => p.colorName === color).length > 0 ?
      this.baseApiUrl + product.productImages.filter(p => p.colorName === color)[0].imagePath :
      this.baseApiUrl + product.productImages[0].imagePath;
  }

}
