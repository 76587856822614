import { BaseApiService } from 'src/app/base-api.service';
import { environment } from './../environments/environment';
import { Component, OnInit, AfterViewInit, HostBinding, OnDestroy } from '@angular/core';
import { Settings, AppSettings } from './app-settings';
import { Router, NavigationEnd } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  public settings: Settings;
  sub: Subscription;
  constructor(public appSettings: AppSettings, public router: Router,
              public overlayContainer: OverlayContainer) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  //  this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
   if (environment.production) {
     BaseApiService.baseApiUrl = 'https://api.jhelumlogistics.com/';
     BaseApiService.baseFrontEndUrl = 'https://seller.jhelumlogistics.com/';
   } else {
     BaseApiService.baseApiUrl = 'https://api.jhelumlogistics.com/';
     BaseApiService.baseFrontEndUrl = 'https://seller.jhelumlogistics.com/';
     //BaseApiService.baseApiUrl = 'https://localhost:5201/';
   }

  //  BaseApiService.baseApiUrl = 'http://localhost:57861/';
  }

  ngAfterViewInit() {
    this.sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
