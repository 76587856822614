import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Color, ProductAttribute } from 'src/app/models';
import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { ColorsService } from 'src/app/colors.service';
import { MatSelectChange } from '@angular/material/select';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-add-product-attribute',
  templateUrl: './add-product-attribute.component.html',
  styleUrls: ['./add-product-attribute.component.scss']
})
export class AddProductAttributeComponent implements OnInit {

  public Editor = ClassicEditor;
  ckeConfig: any;
  sizeChart: string;

  form: FormGroup;
  selectedColor = '';
  productid: any;
  colors: Color[] = [];
  selectedColorName = '';
  constructor(private productAttributesService: ProductAttributesService,
              private router: Router,
              private colorsService: ColorsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
                this.getColors();
               }

  ngOnInit(): void {

    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      //plugins: [ TableProperties, TableCellProperties],
      // table: {
      //   contentToolbar: [
      //     'tableColumn', 'tableRow', 'mergeTableCells',
      //     'tableProperties', 'tableCellProperties'
      //   ],

      //   // Configuration of the TableProperties plugin.
      //   tableProperties: {
      //     // ...
      //   },

      //   // Configuration of the TableCellProperties plugin.
      //   tableCellProperties: {
      //     // ...
      //   }
      // },
      forcePasteAsPlainText: true
    };

    this.activatedRoute.queryParams.subscribe( params => {
      // this.productid = Number(atob(decodeURIComponent(params["q"])));
      this.productid = Number(this.activatedRoute.snapshot.params.id);
    });
  }

  public onChangeSizeChart({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.sizeChart = data;
  }

  getColors() {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.colorsService.getColors().subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.colors = res;

    }, err => {
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      color: [''],
      size: [''],
      dimensions: '',
      weight: '',
      brand: '',
      stock: ['', Validators.required],
      price: ['', Validators.required],
      saleprice: ['', Validators.required],
      bargainedPrice: ['', Validators.required],
      moq: ['',[Validators.required, Validators.pattern('[1-9]{1}[0-9]*')]],
      netWeight: ['',[Validators.required, Validators.pattern('[1-9]{1}[0-9]*.?[0-9]*')]],
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  colorChanged(event: MatSelectChange) {
    this.selectedColorName = event.source.triggerValue;
  }

  navigateToProductAttributes(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(["/seller/product-attributes/" + pid]);
  }
  
  saveProductAttribute() {
    this.spinner.show();

    const productAttribute: ProductAttribute = {
      productID: this.productid,
      color: this.selectedColor,
      colorName: this.selectedColorName,
      size: this.form.value.size,
      dimensions: this.sizeChart,
      weight: this.form.value.weight,
      brand: this.form.value.brand,
      physicalStock: this.form.value.stock,
      price: this.form.value.price,
      moq: this.form.value.moq,
      salePrice: this.form.value.saleprice,
      bargainingPrice: this.form.value.bargainedPrice,
      netWeight: this.form.value.netWeight,
      addedBy: JSON.parse(localStorage.getItem('token')).username
    };

    this.productAttributesService.addAttribute(productAttribute).subscribe(res => {
      this.spinner.hide();
      console.log(res);
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.navigateToProductAttributes(this.productid);
      } else {
        Swal.fire('Error', res.message, 'error');
      }
      
    }, err => {
      this.spinner.hide();
      console.log('Error ', err);
      if (err instanceof HttpResponse) {
        if (err.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
      
    });
  }

}
