import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';
import { SellerSlider, Slider } from 'src/app/models';
import { UsersService } from 'src/app/users.service';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss']
})
export class SlidersComponent implements OnInit {
  baseApiUrl = BaseApiService.baseApiUrl;
  sliders: SellerSlider[] = [];
  slidersSub: Subscription;
  deleteSliderSub: Subscription;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Slider>(this.sliders);
  displayedColumns: string[] = ['imagePath', 'displayOrder', 'actionEdit', 'actionDelete'];
  constructor(private slidersService: UsersService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindSliders();
  }

  bindSliders() {
    this.spinner.show();
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.slidersSub = this.slidersService.getSliders(username).subscribe(res => {
      this.sliders = res;
      this.dataSource = new MatTableDataSource<Slider>(this.sliders);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteSlider(id: number) {
    const deleted = confirm('Are you sure you want to delete this slider?');
    if (deleted) {
      this.spinner.show();
      this.deleteSliderSub = this.slidersService.deleteSlider(id).subscribe(res => {
        alert('Slider deleted successfully.');
        this.bindSliders();
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        console.log(error);
        this.spinner.hide();
      });
    }

  }

  ngOnDestroy() {
    if (this.deleteSliderSub != null) {
      this.deleteSliderSub.unsubscribe();
    }
    this.slidersSub.unsubscribe();
  }

}
