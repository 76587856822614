import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincodesService } from 'src/app/seller-pincodes.service';

@Component({
  selector: 'app-add-pincode',
  templateUrl: './add-pincode.component.html',
  styleUrls: ['./add-pincode.component.scss']
})
export class AddPincodeComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addShippingPincodeSub: Subscription;
  constructor(private shippingPincodesService: SellerPincodesService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      fromPincode: ['', Validators.required],
      toPincode: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincode() {
    this.spinner.show();
    const username = JSON.parse(localStorage.getItem('token')).username;
    const shippingPincode = {
      fromPincode : this.form.value.fromPincode,
      toPincode: this.form.value.toPincode,
      username: username
    };
    this.addShippingPincodeSub = this.shippingPincodesService.addShippingPincode(shippingPincode).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/seller/pincodes']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }


  ngOnDestroy() {
    this.addShippingPincodeSub.unsubscribe();
  }


}
