import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincode } from 'src/app/models';
import { SellerPincodesService } from 'src/app/seller-pincodes.service';

@Component({
  selector: 'app-edit-pincode',
  templateUrl: './edit-pincode.component.html',
  styleUrls: ['./edit-pincode.component.scss']
})
export class EditPincodeComponent implements OnInit {

  shippingPincode: SellerPincode = null;
  form: FormGroup;
  pincodeSub: Subscription;
  savePincodeSub: Subscription;
  constructor(private shippingPincodesService: SellerPincodesService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.spinner.show();
    this.pincodeSub = this.shippingPincodesService.getShippingPincode(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.shippingPincode = res;
      this.form.patchValue({
        fromPincode: res.fromPincode,
        toPincode: res.toPincode
      });
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
    });
  }

  createForm() {
    this.form = this.fb.group({
      fromPincode: ['', Validators.required],
      toPincode: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincode() {
    this.spinner.show();

    this.shippingPincode.fromPincode = this.form.value.fromPincode;
    this.shippingPincode.toPincode = this.form.value.toPincode;

    this.savePincodeSub = this.shippingPincodesService.updateShippingPincode(this.shippingPincode).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/seller/pincodes']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.pincodeSub.unsubscribe();
    this.savePincodeSub.unsubscribe();
  }

}
