import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincode } from 'src/app/models';
import { SellerPincodesService } from 'src/app/seller-pincodes.service';

@Component({
  selector: 'app-pincodes',
  templateUrl: './pincodes.component.html',
  styleUrls: ['./pincodes.component.scss']
})
export class PincodesComponent implements OnInit, OnDestroy {

  shippingPincodes: SellerPincode[] = [];
  pincodesSub: Subscription;
  deletePincodeSub: Subscription = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<SellerPincode>(this.shippingPincodes);
  displayedColumns: string[] = ['fromPincode', 'toPincode', 'actionSlabs', 'actionEdit', 'actionDelete'];

  totalCount = 0;
  currentPage = 0;
  pageSize = 10;
  fromPincode = '';
  toPincode = '';
  sortBy = 'FromPincode';
  direction = 'asc';

  constructor(private shippingPincodesService: SellerPincodesService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindPincodes();
  }

  doFilterFromPincode(event) {
    this.fromPincode = event.target.value;
    this.bindPincodes();
  }

  doFilterToPincode(event) {
    this.toPincode = event.target.value;
    this.bindPincodes();
  }

  doSorting(event) {
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindPincodes();
  }

  bindPincodes() {
    this.spinner.show();
    this.pincodesSub = this.shippingPincodesService.getShippingPincodes(this.fromPincode,
              this.toPincode, this.currentPage, this.pageSize, this.sortBy + ' ' + this.direction).subscribe(res => {
      this.shippingPincodes = res.shippingPincodes;
      this.totalCount = res.totalItems;
      this.dataSource = new MatTableDataSource<SellerPincode>(this.shippingPincodes);
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindPincodes();
  }

  deletePincode(id: number) {
    const deleted = confirm('Are you sure you want to delete this pincode?');
    if (deleted) {
      this.spinner.show();
      this.deletePincodeSub = this.shippingPincodesService.deleteShippingPincode(id).subscribe(res => {
        if (res.added) {
          alert(res.message);
          this.bindPincodes();
          this.spinner.hide();
        } else {
          alert(res.message);
          this.spinner.hide();
        }
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }
  }

  ngOnDestroy() {
    if (this.deletePincodeSub != null) {
      this.deletePincodeSub.unsubscribe();
    }
    this.pincodesSub.unsubscribe();
  }

}
