import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SeasonalDiscount } from 'src/app/models';
import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { ColorsService } from 'src/app/colors.service';
import { MatSelectChange } from '@angular/material/select';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { ProductDiscountService } from 'src/app/product-discount.service';
import { SeasonalDiscountService } from 'src/app/seasonal-discount.service';

@Component({
  selector: 'app-add-seasonal-discount',
  templateUrl: './add-seasonal-discount.component.html',
  styleUrls: ['./add-seasonal-discount.component.scss']
})
export class AddSeasonalDiscountComponent implements OnInit {

  form: FormGroup;
  public selectedMoments = [ null, null];
  errors = {
    invalidRange: false,
    invalidCoupon: false
  };
  constructor(private seasonalDiscountService: SeasonalDiscountService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( params => {
      // this.attributeId = Number(atob(decodeURIComponent(params["q"])));
    });
  }



  createForm() {
    this.form = this.fb.group({
      couponCode: ['', [Validators.required, Validators.minLength(6)] ],
      discountPercentage: [, Validators.required ],
    });
  }

  onScheduleDtChange() {
    this.errors.invalidRange = false;
    // console.log(this.selectedMoments[0]);
    // console.log(this.selectedMoments[1]);
  }



  navigateToSeasonalDiscounts() {
    this.router.navigate(['/seller/seasonal-discounts']);
  }

  convertDate(date: Date) {
    const MM = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    const yyyyMMdd = [date.getFullYear(), (MM > 9 ? '' : '0') + MM, (dd > 9 ? '' : '0') + dd].join('-');
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const hhmmsss = [(hh > 9 ? '' : '0') + hh, (mm > 9 ? '' : '0') + mm, (ss > 9 ? '' : '0') + ss].join(':');

    return yyyyMMdd + ' ' + hhmmsss;
  }

  saveSeasonalDiscount() {

    if (this.selectedMoments[0] === null || this.selectedMoments[1]  === null) {
      this.errors.invalidRange = true;
      return;
    }


    if (String(this.form.value.couponCode).trim().length < 6) {
      this.errors.invalidCoupon = true;
      return;
    }
    console.log(this.selectedMoments);
    // return;
    // this.selectedMomentsStr
    this.spinner.show();

    const sellerUsername = JSON.parse(localStorage.getItem('token')).username;
    const seasonalDiscount: SeasonalDiscount = {
      sellerUsername,
      discountPercentage: this.form.value.discountPercentage,
      couponCode: this.form.value.couponCode,
      fromDateTime: this.convertDate(this.selectedMoments[0]),
      toDateTime: this.convertDate(this.selectedMoments[1])
    };

    console.log(seasonalDiscount);
    this.seasonalDiscountService.addSeasonalDiscount(seasonalDiscount).subscribe(res => {
      this.spinner.hide();
      console.log(res);
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.navigateToSeasonalDiscounts();
      } else {
        Swal.fire('Error', res.message, 'error');
      }

    }, err => {
      this.spinner.hide();
      console.log('Error ', err);
      if (err instanceof HttpResponse) {
        if (err.status === 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }

    });
  }


}
