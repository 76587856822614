import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-attributes',
  templateUrl: './product-attributes.component.html',
  styleUrls: ['./product-attributes.component.scss']
})
export class ProductAttributesComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  productAttributes: any[] = [];
  productid: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.productAttributes);
  displayedColumns: string[] = ['color', 'size', 'weight', 'brand', 'stock', 'mrp', 'salePrice', 'actionEdit', 'actionDelete', 'actionDiscount'];

  constructor(private productAttributesService: ProductAttributesService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.bindProductAttributes();
  }

  bindProductAttributes() {
    this.spinner.show();

    this.activatedRoute.queryParams.subscribe( params => {
      // this.productid = Number(atob(decodeURIComponent(params["q"])));
      this.productid = Number(this.activatedRoute.snapshot.params.id);

      this.productAttributesService.getAttributesForProduct(this.productid).subscribe(res => {
        this.productAttributes = res;
        console.log(this.productAttributes);
        this.dataSource = new MatTableDataSource<any>(this.productAttributes);
        this.dataSource.paginator = this.paginator;
        this.spinner.hide();
      }, err => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
        this.spinner.hide();
      });

    });


  }

  deleteProductAttribute(id) {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete this attribute?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.productAttributesService.deleteAttribute(id).subscribe(res => {
          this.spinner.hide();
          Swal.fire('Message', 'Product Attribute deleted successfully.', 'success');
          this.bindProductAttributes();
        }, err => {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
          this.spinner.hide();
        });
      }
    });

  }


  navigateToAddAttributes(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(['/seller/add-product-attribute/' + pid]);
  }


  navigateToEditAttributes(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(['/seller/edit-product-attribute/' + pid]);
  }

  navigateToAddDiscount(pid: number) {
    this.router.navigate(['/seller/product-discount/' + pid]);
  }


}
