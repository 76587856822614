import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Color } from './models';

@Injectable({
  providedIn: 'root'
})
export class ColorsService {

  constructor(private http: HttpClient) { }

  getColors(): Observable<Color[]> {
    const url = BaseApiService.baseApiUrl + 'api/Color/GetColors';
    return this.http.get<Color[]>(url);
  }

  getColor(id: number): Observable<Color> {
    const url = BaseApiService.baseApiUrl + 'api/Color/Get/' + id;
    return this.http.get<Color>(url);
  }

  addColor(color: Color): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Color/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<any>(url, color, {headers});
  }

  updateColor(color: Color): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Color/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, color, {headers});
  }

  deleteColor(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Color/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, {headers});
  }
}
