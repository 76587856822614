import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { CountriesService } from 'src/app/countries.service';
import { CustomOrderDetails, CustomOrderHistory, CustomOrder, CustomOrdersCategory } from 'src/app/models';
import { OrdersService } from 'src/app/orders.service';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { StatesService } from 'src/app/states.service';
import { UsersService } from 'src/app/users.service';
import Swal from 'sweetalert2';
import { SelectCustomOrderProductComponent } from '../select-custom-order-product/select-custom-order-product.component';

@Component({
  selector: 'app-add-custom-order',
  templateUrl: './add-custom-order.component.html',
  styleUrls: ['./add-custom-order.component.scss']
})
export class AddCustomOrderComponent implements OnInit {
  @ViewChild('content') private content: any;
  menus: any = [];
  categories: any = [];
  customOrdersCategories: CustomOrdersCategory[] = [];
  products: any = [];
  addedProducts: any = [];
  colors: any = [];
  hasColors = true;
  sizes: any = [];
  hasSizes = false;
  weights: any = [];
  hasWeights = false;
  selectedColor = '';
  selectedSize = '';
  selectedWeight = '';
  form: FormGroup;
  myProductsCategoryDisabled = true;
  newProductDisabled = false;
  productDetailsActive = true;
  customerDetailsActive = false;
  paymentDetailsActive = false;
  customerName = '';
  customerEmail = '';
  customerMobile = '';
  customerAddress = '';
  customerPincode = '';
  customerCountry = 0;
  customerCountryName = '';
  customerState = 0;
  customerStateName = '';
  customerCity = '';
  customerLocationLink = '';
  orderNote = '';
  countries: any[];
  states: any[];
  subTotal = 0;
  totalWeight = 0;
  discount = 0;
  discountText = '';
  totalAmount = 0;
  shippingCharges = 0;
  paymentMethod = '';
  deliveryType = 'Normal';
  deliveryTypes = [];
  transportationMode = 'Two Wheeler';
  pickupLocation = '';
  pickupDateTime = '';
  

  constructor(private fb: FormBuilder,
              private countriesService: CountriesService,
              private statesService: StatesService,
              private ordersService: OrdersService,
              private userService: UsersService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private attributesService: ProductAttributesService,
              private zone: NgZone,
              private dialog: MatDialog) { }

  async ngOnInit() {
    this.spinner.show();
    this.countriesService.getCountries().subscribe(async res => {
      this.countries = res;
      console.log('Countries: ', res);
      this.customerCountry = 101;
      this.customerCountryName = 'India';
      await this.getStatesStart();
      this.spinner.hide();
    }, async err => {
      this.spinner.hide();
    });

    await this.bindCategories();
    await this.bindUser();
  }

  async bindUser() {
    this.spinner.show();
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.userService.getUserByUsernameAdmin(username)
        .subscribe(async res => {
          this.spinner.hide();
          this.pickupLocation = res.businessAddress;
          console.log('Pickup Location: ', this.pickupLocation);
          console.log('Result: ', res);
          // if (res.logisticType === 'Logistics Only') {
          //   this.newProductDisabled = false;
          // } else {
          //   this.newProductDisabled = true;
          // }
        }, async err => {
          this.spinner.hide();
        });
  }

  async bindCategories() {
    this.spinner.show();
    this.ordersService.getCustomOrdersCategories()
        .subscribe(async res => {
          this.spinner.hide();
          this.customOrdersCategories = res;
          console.log('Categories result: ', res);
        }, async err => {
          this.spinner.hide();
        });
  }

  onMenuSelected() {}

  onCategorySelected() {}

  onProductSelected() {}

  toggleMyProductsCategory() {
    this.myProductsCategoryDisabled = !this.myProductsCategoryDisabled;
    this.newProductDisabled = !this.newProductDisabled;
  }

  addProductToList() {
    if (!this.newProductDisabled) {
      const productName = (document.getElementById('productNameToBeAdded') as HTMLInputElement).value;
      const productCategory = (document.getElementById('productCategory') as HTMLInputElement).value;
      const productColor = (document.getElementById('productColor') as HTMLInputElement).value;
      const productSize = (document.getElementById('productSize') as HTMLInputElement).value;
      const productGrossWeight = (document.getElementById('productGrossWeight') as HTMLInputElement).value as string;
      const productQuantity = (document.getElementById('productQuantity') as HTMLInputElement).value as string;
      const productPrice = (document.getElementById('productPrice') as HTMLInputElement).value as string;

      if (productName === '') {
        alert('Please enter Product Name.');
        return;
      }
      if (productCategory === undefined || productCategory === null || productCategory === '') {
        alert('Please enter Product Category.');
        return;
      }
      if (productGrossWeight === '') {
        alert('Please enter Product Gross Weight.');
        return;
      }
      if (productQuantity === '') {
        alert('Please enter Product Quantity.');
        return;
      }
      if (productPrice === '') {
        alert('Please enter Product Price.');
        return;
      }

      this.addedProducts.push({
        id: null,
        name: productName,
        category: productCategory,
        color: productColor,
        size: productSize,
        grossWeight: parseInt(productGrossWeight),
        quantity: parseInt(productQuantity),
        price: parseFloat(productPrice),
        total: parseInt(productQuantity) * parseFloat(productPrice),
        isEditing: false
      });

      (document.getElementById('productNameToBeAdded') as HTMLInputElement).value = '';
      (document.getElementById('productCategory') as HTMLInputElement).value = '';
      (document.getElementById('productColor') as HTMLInputElement).value = '';
      (document.getElementById('productSize') as HTMLInputElement).value = '';
      (document.getElementById('productGrossWeight') as HTMLInputElement).value = '';
      (document.getElementById('productQuantity') as HTMLInputElement).value = '';
      (document.getElementById('productPrice') as HTMLInputElement).value = '';

      setTimeout(() => {
        this.content.scrollToBottom();
        },200);
    }
  }

  navigateToProductDetails() {
    this.productDetailsActive = true;
    this.customerDetailsActive = false;
    this.paymentDetailsActive = false;
  }

  navigateToCustomerDetails() {
    if (this.addedProducts.length === 0) {
      alert('Please add product(s) first...');
      return;
    }
    this.productDetailsActive = false;
    this.customerDetailsActive = true;
    this.paymentDetailsActive = false;
  }

  async navigateToPaymentDetails() {
    this.customerCity = this.customerCity.trim();

    if (this.customerName === '') {
      alert('Please enter customer name');
      return;
    }
    if(!this.customerName.match(/^[a-zA-Z ]+$/)) {
      alert('Only characters allowed for customer name');
      return;
    }
    // if (this.customerEmail === '') {
    //   alert('Please enter customer email');
    //   return;
    // }
    if (this.customerMobile === '') {
      alert('Please enter customer mobile no.');
      return;
    }
    if (this.customerAddress === '') {
      alert('Please enter customer address');
      return;
    }
    if (this.customerPincode === '') {
      alert('Please enter customer pincode');
      return;
    }
    if (this.customerCountry === 0) {
      alert('Please enter customer country');
      return;
    }
    if (this.customerState === 0) {
      alert('Please enter customer state');
      return;
    }
    if (this.customerCity === '') {
      alert('Please enter customer city');
      return;
    }

    if (this.customerCity.toLowerCase() !== 'srinagar') {
      this.deliveryTypes = [];
      this.deliveryTypes.push('Normal');
    } else {
      this.deliveryTypes = [];
      this.deliveryTypes.push('Normal');
      this.deliveryTypes.push('Express');
    }

    this.customerCountryName = this.getCountry();
    this.customerStateName = this.getState();

    this.subTotal = 0;
    this.totalWeight = 0;

    this.addedProducts.forEach(product => {
      this.subTotal += product.total;
      this.totalWeight += (product.grossWeight * product.quantity);
    });

    await this.getShippingCharges();
    this.productDetailsActive = false;
    this.customerDetailsActive = false;
    this.paymentDetailsActive = true;
  }

  async getStates() {
    this.spinner.show();

    const country = (document.getElementById('customerCountry') as HTMLSelectElement).value;

    this.statesService.getByCountry(+country).subscribe(async res => {
      this.states = res;
      this.spinner.hide();
    }, async err => {
      this.spinner.hide();
    });
  }
  async getStatesStart() {
    this.spinner.show();
    this.statesService.getByCountry(101).subscribe(async res => {
      this.states = res;
      this.customerStateName = 'Jammu and Kashmir';
      this.customerState = 15;
      this.spinner.hide();
    }, async err => {
      this.spinner.hide();
    });
  }

  getCountry() {
    const country = (document.getElementById('customerCountry') as HTMLSelectElement).value;
    return this.countries.filter(c => c.id === this.customerCountry)[0].name;
  }

  getState() {
    const state = (document.getElementById('customerState') as HTMLSelectElement).value;
    return this.states.filter(s => s.id === this.customerState)[0].name;
  }

  async getShippingCharges() {
    this.spinner.show();
    this.ordersService.getShippingChargesByWeight(this.totalWeight, this.customerPincode).subscribe(async res => {
      if (this.deliveryType === 'Normal' && this.transportationMode === 'Two Wheeler') {
        this.shippingCharges = parseFloat(res.shippingCharges);
      } else if (this.deliveryType === 'Normal' && this.transportationMode === 'Four Wheeler') {
        this.shippingCharges = parseFloat(res.shippingChargesFourWheeler);
      } else if (this.deliveryType === 'Express' && this.transportationMode === 'Two Wheeler') {
        this.shippingCharges = parseFloat(res.shippingChargesExpress);
      } else if (this.deliveryType === 'Express' && this.transportationMode === 'Four Wheeler') {
        this.shippingCharges = parseFloat(res.shippingChargesFourWheelerExpress);
      }
      this.totalAmount = 0;
      this.totalAmount = (this.subTotal + this.shippingCharges) - this.discount;
      this.spinner.hide();
    }, async err => {
      this.spinner.hide();
    });
  }

  discountEntered() {
    if (this.discountText === undefined || this.discountText === null || this.discountText === '') {
      this.discount = 0;
    } else {
      this.discount = parseFloat(this.discountText);
    }

    this.totalAmount = 0;
    this.totalAmount = (this.subTotal + this.shippingCharges) - this.discount;
  }

  async placeOrder() {
    console.log('Shipping Charges: ', this.shippingCharges);
    if(this.paymentMethod == '') {
      alert('Please select payment method...');
      return;
    }

    const orderItems: CustomOrderDetails[] = [];


    this.addedProducts.forEach(item => {
      orderItems.push({
        id: 0,
        orderID: 0,
        //productID: item.id,
        productName: item.name,
        price: item.price,
        color: item.color,
        size: item.size,
        weight: item.weight,
        category: item.category,
        quantity: item.quantity,
        total: item.total,
        netWeight: item.netWeight,
        totalNetWeight: item.grossWeight
      });

    });

    const orderHistory: CustomOrderHistory = {
      comments: 'Order was placed',
      id: 0,
      orderID: 0
    };

    const order: CustomOrder = {
      id: 0,
      customerName: this.customerName,
      customerEmail: this.customerEmail,
      customerMobile: this.customerMobile,
      customerCountry: this.customerCountryName,
      customerState: this.customerStateName,
      customerCity: this.customerCity,
      customerAddress: this.customerAddress,
      locationLink: this.customerLocationLink,
      orderNote: this.orderNote,
      pinCode: this.customerPincode,
      subTotal: this.subTotal,
      shippingCharges: this.shippingCharges,
      discount: this.discount,
      total: this.totalAmount,
      returnShippingCharges: 0,
      orderStatus: 'Pending',
      username: this.customerMobile,
      paymentMethod: this.paymentMethod,
      transportationMode: this.transportationMode,
      deliveryMode: this.deliveryType,
      pickupLocation: this.pickupLocation,
      pickupDateTime: this.pickupDateTime,
      sellerUsername: JSON.parse(localStorage.getItem('token')).username
    };


    const orderObject = {
      orderItems,
      order,
      orderHistory,
      from: 'Website',
      websiteVersion: BaseApiService.version
    };

    console.log('orderObject: ', orderObject);

    this.spinner.show();

    this.ordersService.addCustomOrder(orderObject)
        .subscribe(async res => {
          this.spinner.hide();
          
          if (res.added) {
            Swal.fire('Message', res.message, 'success');
            // await this.router.navigateByUrl('/custom-orders');
            await this.zone.run(() => this.router.navigateByUrl('/seller/custom-orders'));
          } else {
            Swal.fire('Error', res.message, 'error');
          }
        })
  }

  removeProduct(item: any) {
    const index = this.addedProducts.findIndex(d => d.id === item.id &&
        d.name === item.name && d.category === item.category &&
        d.color === item.color && d.size === item.size);
    this.addedProducts.splice(index, 1);
  }

  doEditing(item: any) {
    item.isEditing = true;
  }

  updateProduct(item: any) {
    const index = this.addedProducts.findIndex(d => d.id === item.id &&
        d.name === item.name && d.category === item.category &&
        d.color === item.color && d.size === item.size);

    const productName = (document.getElementById('productNameEdit') as HTMLInputElement).value;
    const productCategory = (document.getElementById('productCategoryEdit') as HTMLInputElement).value;
    const productColor = (document.getElementById('productColorEdit') as HTMLInputElement).value;
    const productSize = (document.getElementById('productSizeEdit') as HTMLInputElement).value;
    const productGrossWeight = (document.getElementById('productGrossWeightEdit') as HTMLInputElement).value as string;
    const productQuantity = (document.getElementById('productQuantityEdit') as HTMLInputElement).value as string;
    const productPrice = (document.getElementById('productPriceEdit') as HTMLInputElement).value as string;

    if (productName === '') {
      alert('Please enter Product Name.');
      return;
    }
    if (productCategory === '') {
      alert('Please enter Product Category.');
      return;
    }
    if (productGrossWeight === '') {
      alert('Please enter Product Gross Weight.');
      return;
    }
    if (productQuantity === '') {
      alert('Please enter Product Quantity.');
      return;
    }
    if (productPrice === '') {
      alert('Please enter Product Price.');
      return;
    }
    this.addedProducts[index].name = productName;
    this.addedProducts[index].category = productCategory;
    this.addedProducts[index].color = productColor;
    this.addedProducts[index].size = productSize;
    this.addedProducts[index].grossWeight = parseInt(productGrossWeight);
    this.addedProducts[index].quantity = parseInt(productQuantity);
    this.addedProducts[index].price = parseFloat(productPrice);
    this.addedProducts[index].total = parseInt(productQuantity) * parseFloat(productPrice);
    this.addedProducts[index].isEditing = false;

    // this.addedProducts.push({
    //   id: null,
    //   name: productName,
    //   category: productCategory,
    //   color: productColor,
    //   size: productSize,
    //   grossWeight: parseInt(productGrossWeight),
    //   quantity: parseInt(productQuantity),
    //   price: parseFloat(productPrice),
    //   total: parseInt(productQuantity) * parseFloat(productPrice),
    //   isEditing: false
    // });

  }

  cancelUpdate(item: any) {
    item.isEditing = false;
  }

  async showSelectProductDialog() {
    let dialogRef = this.dialog.open(SelectCustomOrderProductComponent, {
      height: '400px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((data) => {
          console.log('Data received: ', data);
          if (data !== null) {
            this.addedProducts.push(data);
          }
          setTimeout(() => {
            const elmnt = document.getElementById('addedProductsDiv');
          elmnt.scrollIntoView();
          },200);
        });
  }

  async deliveryTypeChanged() {
    if (this.deliveryType === 'Express' && this.customerCity.toLowerCase() !== 'srinagar') {
      this.deliveryType = 'Normal';
      alert('Express delivery not available outside Srinagar.');
      return;
    }
    await this.getShippingCharges();
  }

async setTransportationMode(transportationMode: any) {
    this.transportationMode = transportationMode;
    const transportationButtons = document.getElementsByClassName('transportationButtons');
    console.log('num transportationButtons: ', transportationButtons.length);
    if (transportationMode === 'Two Wheeler') {
      transportationButtons[0].classList.remove('transportationUnselected');
      transportationButtons[0].classList.add('transportationSelected');

      transportationButtons[1].classList.remove('transportationSelected');
      transportationButtons[1].classList.add('transportationUnselected');
    } else {
      transportationButtons[1].classList.remove('transportationUnselected');
      transportationButtons[1].classList.add('transportationSelected');

      transportationButtons[0].classList.remove('transportationSelected');
      transportationButtons[0].classList.add('transportationUnselected');
    }

    await this.getShippingCharges();
}

  addMore() {
    this.content.scrollToTop();
  }

  async getExistingCustomerDetails() {
    this.spinner.show();
    this.ordersService.getCustomOrderDetailsForSellerUser(this.customerMobile)
        .subscribe(async res => {
          this.spinner.hide();
          if(res) {
            this.customerName = res.customerName;
            this.customerAddress = res.customerAddress;
            this.customerPincode =res.pinCode;
            this.customerEmail = res.customerEmail;
            this.customerCity = res.customerCity;
          }
        }, async err => {
          this.spinner.hide();
        });
  }

}
