import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincodeSlab } from 'src/app/models';
import { SellerPincodeSlabsService } from 'src/app/seller-pincode-slabs.service';

@Component({
  selector: 'app-pincode-slabs',
  templateUrl: './pincode-slabs.component.html',
  styleUrls: ['./pincode-slabs.component.scss']
})
export class PincodeSlabsComponent implements OnInit, OnDestroy {

  shippingPincodeSlabs: SellerPincodeSlab[] = [];
  pincodesSub: Subscription;
  deletePincodeSub: Subscription = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<SellerPincodeSlab>(this.shippingPincodeSlabs);
  displayedColumns: string[] = ['fromWeight', 'toWeight', 'shippingCharges', 'actionEdit', 'actionDelete'];

  constructor(private shippingPincodeSlabService: SellerPincodeSlabsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindPincodeSlabs();
  }

  bindPincodeSlabs() {
    this.spinner.show();
    this.pincodesSub = this.shippingPincodeSlabService.getSlabs(
      this.activatedRoute.snapshot.params.id
    ).subscribe(res => {
      this.shippingPincodeSlabs = res;
      this.dataSource = new MatTableDataSource<SellerPincodeSlab>(this.shippingPincodeSlabs);
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deletePincodeSlab(id: number) {
    const deleted = confirm('Are you sure you want to delete this slab?');
    if (deleted) {
      this.spinner.show();
      this.deletePincodeSub = this.shippingPincodeSlabService.deleteSlab(id).subscribe(res => {
        if (res.added) {
          alert(res.message);
          this.bindPincodeSlabs();
          this.spinner.hide();
        } else {
          alert(res.message);
          this.spinner.hide();
        }
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }
  }

  ngOnDestroy() {
    if (this.deletePincodeSub != null) {
      this.deletePincodeSub.unsubscribe();
    }
    this.pincodesSub.unsubscribe();
  }

  navigateToAddSlab() {
    this.router.navigate(['/seller/add-pincode-slab/' + this.activatedRoute.snapshot.params.id]);
  }

}
