import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate-qr-code',
  templateUrl: './generate-qr-code.component.html',
  styleUrls: ['./generate-qr-code.component.scss']
})
export class GenerateQrCodeComponent implements OnInit {
  user: User = null;

  constructor(private usersService: UsersService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.spinner.show();
    this.usersService.getUserByUsername(username).subscribe(res => {
      this.user = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  generateQrCode() {
    this.spinner.show();
    this.user.modifiedBy = JSON.parse(localStorage.getItem('token')).username;
    this.usersService.generateQrCode(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'QR Code generated successfully.', 'success');
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }
}
