import { Component, OnInit, OnDestroy } from '@angular/core';
import { Color, Stock } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from 'src/app/stock.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { ColorsService } from 'src/app/colors.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss']
})
export class AddStockComponent implements OnInit, OnDestroy {

  stock: Stock;
  form: FormGroup;
  saveStockSub: Subscription = null;
  productId: any;

  colors: Color[] = [];
  constructor(private stockService: StockService,
              private colorsService: ColorsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
                this.getColors();
              }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( params => {
      // this.productId = Number(atob(decodeURIComponent(params["q"])));
      this.productId = this.activatedRoute.snapshot.params.id;
    });
  }

  getColors() {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.colorsService.getColors().subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.colors = res;

    }, err => {
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      stock: ['', Validators.required],
      color: '',
      size: '',
      weight: '',
      brand: ''
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveStock() {
    this.spinner.show();
    const username = JSON.parse(localStorage.getItem('token')).username;
    const stock = {
      physicalStock : this.form.value.stock,
      productID: this.productId,
      color: this.form.value.color,
      size: this.form.value.size,
      weight: this.form.value.weight,
      brand: this.form.value.brand,
      addedBy: username
    };
    this.saveStockSub = this.stockService.addStock(stock).subscribe(res => {
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.router.navigate(['/seller/stocks/' + this.productId]);
      } else {
        Swal.fire('Error', res.message, 'error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.saveStockSub != null) {
      this.saveStockSub.unsubscribe();
    }
  }

}
