import { BaseApiService } from './../../base-api.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Menu, Category, SubCategory, Product } from 'src/app/models';
import { ProductsService } from 'src/app/products.service';
import { MenuService } from 'src/app/menu.service';
import { CategoryService } from 'src/app/category.service';
import { SubcategoryService } from 'src/app/subcategory.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  pid : number;
  public Editor = ClassicEditor;
  description: string;
  specifications: string;
  ckeConfig: any;
  product: any;
  baseApiUrl = BaseApiService.baseApiUrl;
  @ViewChild( 'editor' ) editorComponent: CKEditorComponent;

  file1: FileUploadModel;
  file2: FileUploadModel;
  file3: FileUploadModel;
  file4: FileUploadModel;

  error: string;
  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  menusSub: Subscription;
  categoriesSub: Subscription;
  subcategoriesSub: Subscription;
  productSub: Subscription;
  saveProductSub: Subscription = null;

  constructor(private productsService: ProductsService,
              private menuService: MenuService,
              private categoryService: CategoryService,
              private subcategoryService: SubcategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      forcePasteAsPlainText: true
    };

    this.bindProduct();
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  bindProduct() {
    this.spinner.show();

    
    this.activatedRoute.queryParams.subscribe( params => {
      this.pid = this.activatedRoute.snapshot.params.id;

      this.productSub = this.productsService.getById(this.pid).subscribe(res => {
        this.product = res;
        this.form.patchValue({
          name: this.product.name,
          itemDescription: this.product.itemDescription,
          shortDescription: this.product.shortDescription,
          specifications: this.product.specifications,
          desctiption: res.description,
          onlinePaymentAvailable: this.product.onlinePaymentAvailable,
          codPaymentAvailable: this.product.codAvailable,
          barcode: this.product.barcode
        });
        this.specifications = res.specifications;
        this.description = res.description;
        this.editorComponent.editorInstance.setData(this.description);
        this.spinner.hide();
        this.bindMenus();
      });
    });

  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
      this.bindCategories(this.product.menuID);
    });
  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(menuId).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
      this.bindSubCategories(this.product.categoryID);
    });
  }

  bindSubCategories(categoryId: number) {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(categoryId).subscribe(res => {
      this.subcategories = res;
      this.form.patchValue({
        menu: this.product.menuID,
        category: this.product.categoryID,
        subcategory: this.product.subCategoryID
      });
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      itemDescription: [''],
      shortDescription: ['', Validators.required],
      specifications: ['', Validators.required],
      description: ['', Validators.required],
      menu: ['', Validators.required],
      barcode: [''],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      codPaymentAvailable: ['', Validators.required],
      onlinePaymentAvailable: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }

  

  public onChangeDescription({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.description = data;
  }

  public onChangeSpecifications({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.specifications = data;
  }

  saveProduct() {
    this.spinner.show();
    const product: Product = new Product();
    product.id = this.product.id;
    product.addedOn = this.product.addedOn;
    product.name = this.form.value.name;
    product.itemDescription = this.form.value.itemDescription;
    product.shortDescription = this.form.value.shortDescription;
    product.specifications = this.specifications;
    product.description = this.description;
    product.isActive = this.product.isActive;
    product.onlinePaymentAvailable = this.form.value.onlinePaymentAvailable;
    product.codAvailable = this.form.value.codPaymentAvailable;
    product.barcode = this.form.value.barcode;
    product.categoryID = this.form.value.category;
    product.subcategoryID = this.form.value.subcategory;
    product.username = this.product.user.username;
    product.modifiedBy = JSON.parse(localStorage.getItem('token')).username;
    product.keywords = this.product.keywords;
    product.commissionPercentage = this.product.commissionPercentage;
    product.addedBy = this.product.addedBy;

    this.saveProductSub = this.productsService.updateProduct(product).subscribe(res => {
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      if (error instanceof HttpErrorResponse) {
        if (error.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    });
  }

  ngOnDestroy() {
    this.menusSub.unsubscribe();
    this.categoriesSub.unsubscribe();
    this.subcategoriesSub.unsubscribe();
    this.productSub.unsubscribe();
  }


  navigateToProductImages(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(["/seller/product-images/" + pid]);
  }
}
