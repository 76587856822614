import { SubCategory, Category } from './models';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  constructor(private http: HttpClient) { }

  // adds the subcategory to the database
  addSubCategory(subcategory: SubCategory): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/subcategory/post';
    const data = {
      categoryID: subcategory.categoryID,
      subcategoryName: subcategory.subcategoryName,
      subcategoryOrder: subcategory.subcategoryOrder,
      imagePath: subcategory.imagePath
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<boolean>(url, data, {headers});
  }

  // updates the subcategory
  updateSubCategory(subcategory: SubCategory): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/subcategory/put';
    const data = {
      id: subcategory.id,
      categoryID: subcategory.categoryID,
      subcategoryName: subcategory.subcategoryName,
      subcategoryOrder: subcategory.subcategoryOrder,
      imagePath: subcategory.imagePath
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, data, {headers});
  }

  // delete a category
  deleteSubCategory(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/subcategory/delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }

  // gets all menus from api
  getByCategory(categoryId: number): Observable<SubCategory[]> {
    const url = BaseApiService.baseApiUrl + 'api/subcategory/Get/' + categoryId;
    return this.http.get<SubCategory[]>(url);
  }

  // get category by id
  getById(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/subcategory/GetById/' + id;
    return this.http.get<any>(url);
  }

  uploadSubcategoryImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/SubCategory/UploadSubcategoryImage';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    };

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
