import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockService } from 'src/app/stock.service';
import { Stock } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-stock-register',
  templateUrl: './stock-register.component.html',
  styleUrls: ['./stock-register.component.scss']
})
export class StockRegisterComponent implements OnInit {

  stocks: Stock[] =[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.stocks);
  displayedColumns: string[] = ['color', 'size', 'physicalStock', 'toBeDispatched', 'balance'];
  constructor(private spinner: NgxSpinnerService,
              private stockService: StockService) { }

  ngOnInit(): void {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    this.stockService.getStockRegisterBySeller(username).subscribe(res => {
      this.stocks = res;
      this.dataSource = new MatTableDataSource<any>(this.stocks);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}
