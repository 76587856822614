import { Component, OnInit } from '@angular/core';
import { Color, ProductAttribute } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ColorsService } from 'src/app/colors.service';
import { MatSelectChange } from '@angular/material/select';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-edit-product-attribute',
  templateUrl: './edit-product-attribute.component.html',
  styleUrls: ['./edit-product-attribute.component.scss']
})
export class EditProductAttributeComponent implements OnInit {
  public Editor = ClassicEditor;
  sizeChart: string;
  ckeConfig: any;
  productAttribute: ProductAttribute;
  form: FormGroup;
  selectedColor = '';
  productid: any;
  colors: Color[] = [];
  selectedColorName = '';
  constructor(private productAttributesService: ProductAttributesService,
              private router: Router,
              private colorsService: ColorsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
                this.getColors();
               }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      forcePasteAsPlainText: true
    };
    this.bindAttribute();
  }

  getColors() {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.colorsService.getColors().subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.colors = res;

    }, err => {
      this.spinner.hide();
    });
  }

  bindAttribute() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe( params => {
      // this.productid = Number(atob(decodeURIComponent(params["q"])));
      this.productid = Number(this.activatedRoute.snapshot.params.id);
      this.productAttributesService.getProductAttribute(this.productid).subscribe( res => {
        this.productAttribute = res;
        this.spinner.hide();
        this.form.patchValue({
          color : res.color,
          size : res.size,
          dimensions: res.dimensions,
          weight: res.weight,
          brand: res.brand,
          stock: res.physicalStock,
          price: res.price,
          saleprice: res.salePrice,
          bargainedPrice: res.bargainingPrice,
          moq: res.moq,
          netWeight: res.netWeight
        });
        this.sizeChart = res.dimensions;
        this.selectedColor = res.color;
        this.selectedColorName = res.colorName;
      }, err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
    });

  }

  createForm() {
    this.form = this.fb.group({
      color: [''],
      size: [''],
      dimensions: '',
      weight: '',
      brand: '',
      stock: ['', Validators.required],
      price: ['', Validators.required],
      saleprice: ['', Validators.required],
      bargainedPrice: ['', Validators.required],
      moq: ['',[Validators.required, Validators.pattern('[1-9]{1}[0-9]*')]],
      netWeight: ['',[Validators.required, Validators.pattern('[1-9]{1}[0-9]*.?[0-9]*')]],
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  public onChangeSizeChart({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.sizeChart = data;
  }

  saveProductAttribute() {
    this.spinner.show();

    this.productAttribute.color = this.selectedColor;
    this.productAttribute.colorName = this.selectedColorName;
    this.productAttribute.size = this.form.value.size;
    this.productAttribute.dimensions = this.sizeChart;
    this.productAttribute.weight = this.form.value.weight;
    this.productAttribute.brand = this.form.value.brand;
    this.productAttribute.physicalStock = this.form.value.stock;
    this.productAttribute.price = this.form.value.price;
    this.productAttribute.salePrice = this.form.value.saleprice;
    this.productAttribute.bargainingPrice = this.form.value.bargainedPrice;
    this.productAttribute.moq = this.form.value.moq;
    this.productAttribute.netWeight = this.form.value.netWeight;
    this.productAttribute.modifiedBy = JSON.parse(localStorage.getItem('token')).username;

    this.productAttributesService.updateAttribute(this.productAttribute).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
      // const navigationExtras: NavigationExtras = {
      //   queryParams: {'q': encodeURIComponent(btoa(String(this.productAttribute.productID)))}
      // };
      this.router.navigate(["/seller/product-attributes/"+ this.productAttribute.productID]);
      } else {
        Swal.fire('Message', res.message, 'success');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  colorChanged(event: MatSelectChange) {
    this.selectedColorName = event.source.triggerValue;
  }

}
