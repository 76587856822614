import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProductDiscountService } from '../product-discount.service';
import { ProductDiscount } from '../models';


@Component({
  selector: 'app-product-discounts',
  templateUrl: './product-discounts.component.html',
  styleUrls: ['./product-discounts.component.scss']
})
export class ProductDiscountComponent implements OnInit {
  baseApiUrl = BaseApiService.baseApiUrl;
  productDiscounts: ProductDiscount[] = [];
  attributeId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.productDiscounts);
  displayedColumns: string[] = ['couponCode', 'discountPercentage', 'username', 'actionEdit', 'actionDelete'];

  // username: string;
  // attributeID: number;
  // discountPercentage: number;
  // couponCode: string;

  constructor(private productAttributesService: ProductAttributesService,
              private productDiscountService: ProductDiscountService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.bindProductDiscounts();
  }

  bindProductDiscounts() {
    this.spinner.show();

    this.activatedRoute.queryParams.subscribe( params => {
      // this.attributeId = Number(atob(decodeURIComponent(params["q"])));
      this.attributeId = Number(this.activatedRoute.snapshot.params.id);

      this.productDiscountService.getProductDiscounts(this.attributeId).subscribe(res => {
        console.log(res);
        this.productDiscounts = res;
        this.dataSource = new MatTableDataSource<any>(this.productDiscounts);
        this.dataSource.paginator = this.paginator;
        this.spinner.hide();
      }, err => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
        this.spinner.hide();
      });

    });


  }

  deleteProductDiscount(id) {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete this Discount?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.productDiscountService.deleteProductDiscount(id).subscribe(res => {
          this.spinner.hide();
          Swal.fire('Message', 'Product Discount deleted successfully.', 'success');
          this.bindProductDiscounts();
        }, err => {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
          this.spinner.hide();
        });
      }
    });

  }


  navigateToAddAttributes(pid: number) {
    this.router.navigate(['/seller/add-product-attribute/' + pid]);
  }

  navigateToEditProductDiscount(discountId: number, attributeID: number) {
    this.router.navigate([`/seller/edit-product-discount/${discountId}/${attributeID}`]);
  }

  navigateToAddDiscount(aid: number) {
    this.router.navigate(['/seller/add-product-discount/' + aid]);
  }



}
