import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/users.service';
import { matchingPasswords } from 'src/app/theme/utils/app-validators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService,
              private usersService: UsersService, private router: Router) { 
                this.createForm();
              }

  ngOnInit(): void {
    
  }

  createForm() {
    this.form = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('newPassword', 'confirmPassword') });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  changePassword() {
    debugger;
    if(this.form.value.oldPassword === this.form.value.newPassword) {
      Swal.fire('Error', 'Old and New Passwords can not be same.', 'error');
      return;
    }
    this.spinner.show();
    this.usersService.changePassword(this.form.value.oldPassword, this.form.value.newPassword).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire({
          title: 'Message',
          text: res.message,
          icon: 'success',
        }).then((result) => {
          localStorage.removeItem('token');
          this.router.navigate(['/sign-in']);
        });
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

}
