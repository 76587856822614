import { OrdersService } from './../../orders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Order } from './../../models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { ExcelService } from 'src/app/excel.service';
import { HttpEventType } from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
  
  form: FormGroup;
  baseApiUrl = BaseApiService.baseApiUrl;
  percentComplete = 0;
  isInProgress = false;
  orders: Order[] = [];
  ordersSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Order>(this.orders);
  displayedColumns: string[] = ['orderID', 'customerName',
    'customerMobile', 'orderDate', 'orderStatus', 'paymentMethod', 'actionDetails'];


  pageNumber = 0;
  pageSize = 10;
  totalItems = 0;
  totalPages = 0;
  toDate: Date;
  fromDate: Date;

  Pending = false;
  Dispatched = false;
  Delivered = false;
  Returned = false;
  All = true;
  loading = true;
  filteredStatus = '';
  allOrdersFetched = false;
  private unsubscribeAll$: Subject<void> = new Subject();
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService,
    private ordersService: OrdersService, private datePipe: DatePipe,
    private scrollDispatcher: ScrollDispatcher,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private excelService: ExcelService) {
    this.createForm();
  }


  exportToExcel() {
    this.spinner.show();
    this.isInProgress = true;

    this.ordersService.downloadOrdersForSeller(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.filteredStatus).subscribe(res => {
        this.spinner.hide();
        switch (res.type) {
          case HttpEventType.DownloadProgress:
            this.isInProgress = true;
            this.percentComplete = Math.round((res.loaded / res.total) * 100);
            break;
          case HttpEventType.Response:
            this.isInProgress = false;
            this.percentComplete = 0;
            const downloadedFile = new Blob([res.body]);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'Orders_' + this.form.value.fromDate + '_' + this.form.value.toDate + '_' + status + '.xlsx';
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

  setDefaultRage() {
    this.toDate = new Date();
    const d = new Date();
    d.setDate(this.toDate.getDate() - 10);
    this.fromDate = d;
  }

  ngOnInit(): void {
    this.setDefaultRage();
    this.initVals();
    this.getOrders(true);
  }

  initVals() {
    this.pageNumber = 0;
    this.totalItems = 0;
    this.totalPages = 1;
    this.orders = [];
    this.allOrdersFetched = false;
  }
  dateChanged() {
    this.initVals();
    this.getOrders(false);
  }

  filter(val: string) {
    this.initVals();
    this.filteredStatus = val;
    switch(val) {
      case 'Pending':
        this.Pending = true;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = false;
        this.All = false;
        break;
      case 'Dispatched':
        this.Pending = false;
        this.Dispatched = true;
        this.Delivered = false;
        this.Returned = false;
        this.All = false;
        break;
      case 'Delivered':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = true;
        this.Returned = false;
        this.All = false;
        break;
      case 'Returned':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = true;
        this.All = false;
        break;
      case 'All':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = false;
        this.All = true;
        this.filteredStatus = '';
        break;
    }
    this.getOrders(false);
  }

  exists(element) {
    let isExisting = false;
    for(let i=0; i< this.orders.length; i++) {
      if (this.orders[i].id === element.id) {
        isExisting = true;
        break;
      }
    }
    return isExisting;
  }


  getOrders(firstCall) {

    this.spinner.show();
    this.ordersSub = this.ordersService.getOrdersForSeller(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.filteredStatus,
      this.pageNumber, this.pageSize).subscribe(res => {
        this.totalItems = res.totalItems;


        // console.log(firstCall);
        if (firstCall || this.totalItems == 0 || this.pageNumber == 0) {
          this.totalItems = res.totalItems;
          if (this.totalItems > 0) {
            this.totalPages = Math.ceil(((this.totalItems - 1) / this.pageSize));
            // console.log(this.totalPages);
          }

        }

        if (this.totalItems > 0) {
          if (res.orders.length == 0 || res.orders.length < 10) {
            if (this.orders.length === this.totalItems) {
              this.allOrdersFetched = true;
            }           
          }
        }

        res.orders.forEach(element => {
          if (!this.exists(element)) {
            this.orders.push(element);
          }            
        });
        // console.log(this.orders);
        this.changeDetector.detectChanges();
      
  
        this.spinner.hide();
      }, err => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
  }

  createForm() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  showOrders() {
    if (this.totalItems === 0) {
      this.getOrders(true);
    } else {
      this.getOrders(false);
    }
  }

  ngOnDestroy() {
    if (this.ordersSub != null) {
      this.ordersSub.unsubscribe();
    }

    if (this.unsubscribeAll$ != null) {
      this.unsubscribeAll$.next();
      this.unsubscribeAll$.unsubscribe();
    }
  }

  navigateToOrderDetails(id: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(id)))}
    // };
    this.router.navigate(["/seller/order-details/" + id]);
  }


  ngAfterViewInit(): void {
    this.scrollDispatcher.scrolled().pipe(takeUntil(this.unsubscribeAll$),
        filter(event => this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength())
        // filter(event => this.virtualScroll.measureScrollOffset('bottom') === 0)
      ).subscribe(event => {
        console.log(); // don't commit it

        

        // console.log("======start===========");
        // console.log(this.pageNumber);
        // console.log(this.totalPages);
        // console.log(this.allOrdersFetched);
        // console.log("======end===========");
        if (!this.allOrdersFetched && this.pageNumber < (this.totalPages - 1)) {
          this.pageNumber++;
          this.getOrders(false);
        } else if (this.totalItems == 0) {
          this.getOrders(true);
        }


      }, error => {
        console.error('JP - Error in scrollDispatcher: ', error);
      });
      
  }
}
