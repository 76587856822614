import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincodeSlab } from 'src/app/models';
import { SellerPincodeSlabsService } from 'src/app/seller-pincode-slabs.service';

@Component({
  selector: 'app-edit-pincode-slab',
  templateUrl: './edit-pincode-slab.component.html',
  styleUrls: ['./edit-pincode-slab.component.scss']
})
export class EditPincodeSlabComponent implements OnInit, OnDestroy {

  form: FormGroup;
  shippingPincodeSlab: SellerPincodeSlab;
  addShippingPincodeSub: Subscription;
  constructor(private shippingPincodeSlabService: SellerPincodeSlabsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindShippingPincodeSlab();
  }

  createForm() {
    this.form = this.fb.group({
      fromWeight: ['', Validators.required],
      toWeight: ['', Validators.required],
      shippingCharges: ['', Validators.required],
      deliveredWithin: ['', Validators.required]
    });
  }

  bindShippingPincodeSlab() {
    this.spinner.show();
    this.shippingPincodeSlabService.getSlab(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      this.form.patchValue({
        fromWeight: res.fromWeight,
        toWeight: res.toWeight,
        shippingCharges: res.shippingCharges,
        deliveredWithin: res.deliveryTime
      });
      this.shippingPincodeSlab = res;
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincodeSlab() {
    this.spinner.show();

    this.shippingPincodeSlab.fromWeight = this.form.value.fromWeight;
    this.shippingPincodeSlab.toWeight = this.form.value.toWeight;
    this.shippingPincodeSlab.shippingCharges = this.form.value.shippingCharges;
    this.shippingPincodeSlab.deliveryTime = this.form.value.deliveredWithin;

    this.addShippingPincodeSub = this.shippingPincodeSlabService.updateSlab(this.shippingPincodeSlab).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/seller/pincode-slabs/' + this.activatedRoute.snapshot.params.id]);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.addShippingPincodeSub.unsubscribe();
  }

}
