import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';
import { CustomOrder, CustomOrderDetails, CustomOrderHistory, User } from 'src/app/models';
import { OrdersService } from 'src/app/orders.service';
import { UsersService } from 'src/app/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-order-details',
  templateUrl: './custom-order-details.component.html',
  styleUrls: ['./custom-order-details.component.scss']
})
export class CustomOrderDetailsComponent implements OnInit {
  statusForm: FormGroup;
  commentsForm: FormGroup;
  logisticForm: FormGroup;
  dispatchForm: FormGroup;
  baseApiUrl = BaseApiService.baseApiUrl;
  percentComplete = 0;
  isInProgress = false;
  isOrderNotPending = true;
  users: User[] = [];
  order: CustomOrder = null;
  orderHistories: CustomOrderHistory[];
  orderDetails: CustomOrderDetails[];
  orderSub: Subscription;
  orderHistoriesSub: Subscription;
  orderDetailsSub: Subscription;
  saveStatusSub: Subscription = null;
  saveCommentsSub: Subscription = null;
  confirmOrderSub: Subscription = null;
  jurisdiction = '';
  logisticPartnerName = '';
  newShippingCharges = 0;
  returnForm: FormGroup;
  constructor(private fbComments: FormBuilder, private fbStatus: FormBuilder,
    private fbLogistics: FormBuilder, private fbDispatch: FormBuilder, private usersService: UsersService,
    private ordersService: OrdersService, private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, private router: Router,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.statusForm = this.fbStatus.group({
      status: ['', Validators.required]
    });
    this.commentsForm = this.fbComments.group({
      comments: ['', Validators.required],
      date: ['', Validators.required]
    });

    this.logisticForm = this.fbLogistics.group({
      logisticPartner: ['', Validators.required],
      jurisdiction: ['']
    });

    this.returnForm = this.fbComments.group({
      reason: ['']
    });

    this.dispatchForm = this.fbDispatch.group({
      dispatchCode: ['', Validators.required]
    });

    this.bindOrderDetails();
  }

  bindOrderDetails() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe( params => {
      // this.id = Number(atob(decodeURIComponent(params["q"])));
      this.orderSub = this.ordersService.getCustomOrder(Number(this.activatedRoute.snapshot.params.id)).subscribe(res => {
        this.order = res;
        console.log('Custom order details: ', res);
        this.orderDetailsSub = this.ordersService.getCustomOrderDetails(Number(this.activatedRoute.snapshot.params.id)).subscribe(resOrderDetails => {
          this.orderDetails = resOrderDetails;
          this.orderHistoriesSub = this.ordersService.getCustomOrderHistories(Number(this.activatedRoute.snapshot.params.id)).subscribe(resOrderHistories => {
            this.orderHistories = resOrderHistories;
            this.spinner.hide();
          });
        });
      }, async err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
    }, err => {
      console.log('GetCustomOrder error: ', err);
    });
  }

  getLogisticPartnerDetails() {
    if (this.order.logisticPartnerUsername !== null) {
      this.spinner.show();
      this.usersService.getUserByUsername(this.order.logisticPartnerUsername).subscribe(res => {
        this.spinner.hide();
        this.logisticPartnerName = res.name;
        this.jurisdiction = res.jurisdiction;
      }, err => {
        this.spinner.hide();
      });
    }
  }

  async dispatchOrder() {
    await this.spinner.show();
    this.ordersService.confirmCustomOrder(this.order.id,
        this.dispatchForm.value.dispatchCode).subscribe(async res =>{
      await this.spinner.hide();
      Swal.fire('Message', res.message, 'info');
      if (res.added) {
        this.bindOrderDetails();
      } else {
      }
    }, async err => {
      await this.spinner.hide();
      if (err.status === 401) {
        localStorage.removeItem('token');
        await this.router.navigateByUrl('/sign-in');
      } else {
        console.log(err);
        await this.spinner.hide();
      }

    });
  }

  changeStatus() {
    this.spinner.show();
    this.saveStatusSub = this.ordersService.updateCustomOrderStatus(+this.activatedRoute.snapshot.params.id, this.statusForm.value.status).subscribe(res => {
      if (res.added) {
        alert('Status updated successfully.');
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
      this.bindOrderDetails();
    }, error => {
      this.spinner.hide();
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.router.navigate(['/sign-in']);
        }
      }
    });
  }

  addComments() {
    this.spinner.show();
    this.saveCommentsSub = this.ordersService.addCustomOrderComments(+this.activatedRoute.snapshot.params.id,
      this.datePipe.transform(this.commentsForm.value.date, 'MM/dd/yyyy'),
      this.commentsForm.value.comments).subscribe(res => {
        if (res.added) {
          alert('Comments added successfully.');
        } else {
          alert('Error occured. Please try again...');
        }
        this.spinner.hide();
        this.bindOrderDetails();
      }, error => {
        this.spinner.hide();
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate(['/sign-in']);
          }
        }
      });
  }

  confirmOrder() {
    // this.spinner.show();
    // this.confirmOrderSub = this.ordersService.confirmOrder(+this.activatedRoute.snapshot.params.id).subscribe(res => {
    //   if (res.added) {
    //     alert('Order confirmed successfully.');
    //   } else {
    //     alert('Error occured. Please try again...');
    //   }
    //   this.spinner.hide();
    //   this.bindOrderDetails();
    // }, error => {
    //   this.spinner.hide();
    //   if (error instanceof HttpErrorResponse) {
    //     if (error.status === 401) {
    //       this.router.navigate(['/sign-in']);
    //     }
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.saveCommentsSub != null) {
      this.saveCommentsSub.unsubscribe();
    }
    if (this.saveStatusSub != null) {
      this.saveStatusSub.unsubscribe();
    }
    if (this.confirmOrderSub != null) {
      this.confirmOrderSub.unsubscribe();
    }
    if (this.orderSub != null) {
      this.orderSub.unsubscribe();
    }
    if (this.orderHistoriesSub != null) {
      this.orderHistoriesSub.unsubscribe();
    }
    if (this.orderDetailsSub != null) {
      this.orderDetailsSub.unsubscribe();
    }
  }

  getLogisticPartners() {
    this.spinner.show();
    this.usersService.getLogisticPartnersByJurisdiction(this.logisticForm.value.jurisdiction).subscribe(res => {
      this.users = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  downloadBill() {
    this.spinner.show();
    this.isInProgress = true;
    this.ordersService.downloadCustomOrderBill(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      switch (res.type) {
        case HttpEventType.DownloadProgress:
          this.isInProgress = true;
          this.percentComplete = Math.round((res.loaded / res.total) * 100);
          break;
        case HttpEventType.Response:
          this.isInProgress = false;
          this.percentComplete = 0;
          const downloadedFile = new Blob([res.body]);
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = 'Order_' + this.activatedRoute.snapshot.params.id + '.pdf';
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          break;
      }
    });
  }

  cancelOrder() {
    const done = confirm('Are you sure you want to cancel this order?');
    if (done) {
      const reason = (document.getElementById('reason') as any).value;
      this.spinner.show();
      this.ordersService.cancelCustomOrder(+this.activatedRoute.snapshot.params.id,
        reason, true).subscribe(res => {
          this.spinner.hide();
          if (res.added) {
            alert(res.message);
            this.bindOrderDetails();
          } else {
            alert(res.message);
          }
        }, err => {
          this.spinner.hide();
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.router.navigate(['/sign-in']);
            } else {
              alert('Error occured. Please try again...');
            }
          } else {
            alert('Error occured. Please try again...');
          }
        });
    }
  }

  getProductImage(product, color) {
    return 'https://cdn.browshot.com/static/images/not-found.png';
  }

  updateOrder() {
    this.spinner.show();
    this.ordersService.updateCustomOrder(this.order).subscribe(async res => {
      this.spinner.hide();
      if(res.added) {
        Swal.fire('Message', res.message, 'success');
        this.bindOrderDetails();
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, async err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  imgErrorHandler(event) {
    console.debug(event);
    event.target.src = "https://cdn.browshot.com/static/images/not-found.png";
 }

}
