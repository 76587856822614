import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomizeRoutingModule } from './customize-routing.module';
import { CustomizeComponent } from './customize.component';
import { MaterialModule } from '../material/material.module';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
    imports: [CommonModule, CustomizeRoutingModule, ColorPickerModule,
        FormsModule, ReactiveFormsModule,     MaterialModule,
        FlexLayoutModule.withConfig({addFlexToParent: false})],
    declarations: [CustomizeComponent],
})
export class CustomizeModule {}
