import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { MenuService } from 'src/app/menu.service';
import { ProductAttribute } from 'src/app/models';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { ProductsService } from 'src/app/products.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-custom-order-product',
  templateUrl: './select-custom-order-product.component.html',
  styleUrls: ['./select-custom-order-product.component.scss']
})
export class SelectCustomOrderProductComponent implements OnInit {
  @ViewChild('content') private content: any;
  pbcolor = 'green';
  bufferValue = 75;
  initalProductCount = 0;
  product: any;
  productAttribute: ProductAttribute;
  attributes: ProductAttribute[] = [];

  isLoading = false;
  headerHidden = false;
  allSellersButtons: any[] = [];

  grid = true;
  oneColumn = false;
  list = false;
  options = [];
  showAutocomplete = false;
  query = '';

  slideOpts = {
    initialSlide: 0,
    loop: true,
    autoplay: {
      delay: 6000
    },
    speed: 400,
    setTranslate() {
      const swiper = this;
      swiper.find('.swiper-cube-shadow').css('height', '150px');
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
    },
  };

  nos = [];
  username = '';

  totalAvgRatings = 0;
  totalRatings = 0;
  totalReviews = 0;
  pbValues = [
    { pbValue: 80, count: 80 },
    { pbValue: 40, count: 40 },
    { pbValue: 30, count: 30 },
    { pbValue: 10, count: 10 },
    { pbValue: 5, count: 5 }
  ];

  whatsAppUrl = 'https://api.whatsapp.com/send?';

  user: any;
  url = '';
  menus: any[] = [];
  totalItems = 0;

  tickInterval = 100;
  tickInterval2 = 100;

  showhtmlLoader = false;

  hexDigits = new Array
    ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f');

  baseApiUrl = BaseApiService.baseApiUrl;

  products: any[] = null;
  @ViewChild('sidenav', { static: true }) sidenav: any;
  public sidenavOpen = true;
  private sub: any;
  public viewType = 'grid';
  public viewCol = 33.3;
  public counts = [36, 72, 144];
  public count: any;
  public brands = [];
  public priceFrom = 0;
  public priceTo = 200000;
  public priceLower = 0;
  public priceUpper = 200000;
  public color = '';
  public size = '';
  showMarquee = false;
  public colors = [
    // { name: '#5C6BC0', selected: false },
    // { name: '#66BB6A', selected: false },
    // { name: '#EF5350', selected: false },
    // { name: '#BA68C8', selected: false },
    // { name: '#FF4081', selected: false },
    // { name: '#9575CD', selected: false },
    // { name: '#90CAF9', selected: false }
  ];
  public sizes = [
    // { name: 'S', selected: false },
    // { name: 'M', selected: false },
    // { name: 'L', selected: false },
    // { name: 'XL', selected: false },
    // { name: '2XL', selected: false },
    // { name: '32', selected: false },
    // { name: '36', selected: false },
    // { name: '38', selected: false },
    // { name: '46', selected: false },
    // { name: '52', selected: false },
    // { name: '13.3"', selected: false },
    // { name: '15.4"', selected: false },
    // { name: '17"', selected: false },
    // { name: '21"', selected: false },
    // { name: '23.4"', selected: false }
  ];
  public page = 1;
  public selectedSize = '';
  public selectedColor = '';

  form: FormGroup;


  // price sliders
  maxVal = 50000;
  minVal = 0;
  showTicks = true;
  step = 500;
  minPrice = 0;
  vertical = false;


  maxVal2 = 200000;
  minVal2 = 60000;
  showTicks2 = true;
  step2 = 5000;
  thumbLabel = true;
  maxPrice = 200000;
  showSearchbar = false;
  isProductSearch = false;
  isCategorySearch = true;
  isAlreadyFollowed = false;
  followerCount = 0;
  followerCountMessage = '';

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
  }

  constructor(private dialogRef: MatDialogRef<SelectCustomOrderProductComponent>,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private menuService: MenuService,
    private productsService: ProductsService,
    private attributesService: ProductAttributesService) { }

  async ngOnInit() {
    this.form = this.fb.group({
      category: ['']
    });

    const username = JSON.parse(localStorage.getItem('token')).username;

    console.log(username);
    this.username = username;
    this.count = this.counts[0];

    this.bindMenus();

    if (window.innerWidth < 960) {
      this.sidenavOpen = false;
    }
    if (window.innerWidth < 1280) {
      this.viewCol = 33.3;
    }
  }

  bindMenus() {
    this.isLoading = true;
    this.menuService.getMenusBySeller(this.username).subscribe(res => {
      this.isLoading = false;
      this.menus = res;
      this.form.patchValue({
        category: -1
      });
      this.getProducts();
    }, err => {
      this.isLoading = false;
    });
  }

  categoryChanged() {
    this.colors = [];
    this.sizes = [];
    this.isCategorySearch = true;
    this.isProductSearch = false;
    this.getProducts();
  }

  getProducts() {
    const sort = 0;
    this.spinner.show();
    this.products = [];
    this.totalItems = 0;
    if (this.isCategorySearch) {
      this.productsService.getProductsByUsernameAndCategory(this.username,
        +this.form.value.category, sort, this.priceFrom, this.priceTo,
        this.fixedEncodeURIComponent(this.selectedColor), this.selectedSize, this.count, this.page - 1).subscribe(async res => {
          this.products = res.products;
          this.totalItems = res.totalItems;
          if (this.initalProductCount === 0) {
            this.initalProductCount = this.totalItems;
          }
          this.spinner.hide();
          this.products.forEach((p: any) => {
            this.getFrameColors(p.colors).forEach((c, i) => {
              if (i === 0) {
                c.selected = true;
              } else {
                c.selected = false;
              }
              this[i] = c;
            }, this);
          });
        }, async err => {
          this.spinner.hide();
          console.log('Error occured.');
          console.log(err);
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        });
    } else {
      this.bindProductsBySearch();
    }
  }

  fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, escape);
  }

  getFrameColors(colors) {
    return this.removeDuplicates(colors);
  }

  removeDuplicates(originalArray) {
    const newArray = [];
    for (const i in originalArray) {
      if (!newArray.some(color => color.color === originalArray[i].color)) {
        newArray.push(originalArray[i]);
      }
    }
    return newArray;
  }

  bindProductsBySearch() {
    this.spinner.show();
    this.showAutocomplete = false;
    (document.getElementById('search') as any).blur();
    const username = JSON.parse(localStorage.getItem('token')).username
    this.productsService.getProductsBySearchForSeller(username,
      this.query, 0, 0, 500000, '', '', 36, 0).subscribe(res => {
        this.products = res.products;
        this.totalItems = res.totalItems;
        this.spinner.hide();
        this.showAutocomplete = false;
        (document.getElementById('search') as any).blur();
        this.getFrameColors(this.products).forEach((p: any) => {
          p.colors.forEach((c, i) => {
            if (i === 0) {
              c.selected = true;
            } else {
              c.selected = false;
            }
            this[i] = c;
          }, this);
        });
      }, err => {
        this.spinner.hide();
      });
  }

  performSearch() {
    this.page = 1;
    this.spinner.show();
    this.isCategorySearch = false;
    this.isProductSearch = true;
    this.showAutocomplete = false;
    (document.getElementById('search') as any).blur();
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.productsService.getProductsBySearchForSeller(username,
      this.query, 0, 0, 500000, '', '', 36, 0).subscribe(res => {
        this.products = res.products;
        this.totalItems = res.totalItems;
        this.spinner.hide();
        this.showAutocomplete = false;
        (document.getElementById('search') as any).blur();
      }, err => {
        this.spinner.hide();
      });
  }

  doAutocomplete() {
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.productsService.getAutocompleteListForSeller(username,
      this.query).subscribe(res => {
        // this.options = null;
        // this.options = [];
        this.options = res;
        this.showAutocomplete = true;
      });
  }

  async doSearch(item) {
    this.query = item;
    this.showAutocomplete = false;
    this.isCategorySearch = false;
    this.isProductSearch = true;
    (document.getElementById('search') as any).blur();
    this.page = 1;
    this.spinner.show();
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.productsService.getProductsBySearchForSeller(username,
      this.query, 0, 0, 500000, '', '', 36, 0).subscribe(res => {
        this.products = res.products;
        this.totalItems = res.totalItems;
        this.spinner.hide();
        this.showAutocomplete = false;
        (document.getElementById('search') as any).blur();
        this.products.forEach((p: any) => {
          this.getFrameColors(p.colors).forEach((c, i) => {
            if (i === 0) {
              c.selected = true;
            } else {
              c.selected = false;
            }
            this[i] = c;
          }, this);
        });
      }, err => {
        this.spinner.hide();
      });
  }

  showGrid() {
    this.grid = true;
    this.oneColumn = false;
    this.list = false;
  }

  // List view function
  showList() {
    this.list = true;
    this.grid = false;
    this.oneColumn = false;
  }

  colorSelected(p, cIndex, colorChoosen) {
    this.products.forEach((prod: any, pIndex) => {
      if (prod.id === p.id) {
        this.getFrameColors(p.colors).forEach((c, i) => {
          if (i === cIndex) {
            c.selected = true;
            prod.price = colorChoosen.price;
            prod.salePrice = colorChoosen.salePrice;
            prod.bargainingPrice = colorChoosen.bargainingPrice;
            if (p.colors.length > 1) {
              let found = false;
              p.productImages.forEach(pImage => {
                if (pImage.color === colorChoosen.color && !found) {
                  prod.imagePath = pImage.imagePath;
                  found = true;
                }
              });
            }

          } else {
            c.selected = false;
          }
          this[i] = c;
        }, this);

        this[pIndex] = prod;
      }


    }, this);
  }

  onPageChanged(event) {
    this.page = event;
    const elmnt = document.getElementById('productsDiv');
    elmnt.scrollIntoView();
    this.getProducts();
  }

  selectProduct(product: any) {
    this.product = product;
    console.log('Product: ', product);
    this.getAttributes(product.id);
  }

  getAttributes(id: number) {
    this.spinner.show();
    this.attributesService.getAttributesForProduct(id)
      .subscribe(res => {
        this.spinner.hide();
        this.attributes = res;
        setTimeout(() => {
          const elmnt = document.getElementById('attributesDiv');
          elmnt.scrollIntoView();
        }, 200);
      }, err => {
        this.isLoading = false;
        alert('Error occured. Please try again...');
      });
  }

  async selectAttribute(attribute) {
    console.log('Attribute: ', attribute);
    const quantity = (document.getElementById(attribute.id + 'quantity') as HTMLInputElement).value as string;
    if (quantity === '') {
      alert('Please enter quantity.');
      return;
    }
    const info = {
      id: attribute.productID,
      name: this.product.name,
      category: this.product.categoryName,
      color: attribute.color,
      size: attribute.size,
      weight: attribute.weight,
      price: parseFloat(attribute.salePrice),
      grossWeight: parseInt(attribute.netWeight),
      quantity: parseInt(quantity),
      total: parseFloat(attribute.salePrice) * parseInt(quantity),
      isEditing: false
    };
    await this.dialogRef.close(info);
  }

  async closeModal() {
    await this.dialogRef.close(null);
  }

}
