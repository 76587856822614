import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductsService } from 'src/app/products.service';
import { BaseApiService } from 'src/app/base-api.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { ColorsService } from 'src/app/colors.service';
import { ProductImageService } from 'src/app/product-image.service';
import { Color, ProductImage } from 'src/app/models';
import { MatSelectChange } from '@angular/material/select';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-product-image',
  templateUrl: './edit-product-image.component.html',
  styleUrls: ['./edit-product-image.component.scss']
})
export class EditProductImageComponent implements OnInit {
  baseApiUrl = BaseApiService.baseApiUrl;
  file: FileUploadModel;

  selectedColor = '';

  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;

  form: FormGroup;
  previvewImgURL: any;
  colors: Color[] = [];
  selectedColorName = '';

  imgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  existingImage = null;
  id: number;
  constructor(private productImageService: ProductImageService,
    private fb: FormBuilder,
    private productsService: ProductsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private colorsService: ColorsService,
    private activatedRoute: ActivatedRoute) {
    this.createForm();
    this.getColors();
  }

  ngOnInit() {
    // let aa = 'once upon a time';
    this.activatedRoute.queryParams.subscribe( params => {    
      // this.id = Number(atob(decodeURIComponent(params["q"])));
      this.id = this.activatedRoute.snapshot.params.id;
      // console.log(this.productid);
      this.getProductImage(this.id);
    });
   
  }



  getProductImage(id) {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.productImageService.getProductImage(id).subscribe(res => {
      this.spinner.hide();
      // console.log(res);
      this.existingImage = res;
      if (this.existingImage.imagePath != null || this.existingImage.imagePath != undefined) {
        this.fileName = this.existingImage.imagePath.substring(this.existingImage.imagePath.lastIndexOf('/') + 1);
      }

      this.form.patchValue({
        color: this.existingImage.color
      });

      this.selectedColorName = this.existingImage.colorName;
     
    }, err => {
      this.spinner.hide();
    });
  }

  getColors() {
    // colorsService
    console.log('colorsService');
    this.spinner.show();
    this.colorsService.getColors().subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.colors = res;

    }, err => {
      this.spinner.hide();
    });
  }

  onClickImage() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG") ) {
        this.imgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 200) {
         this.imgValidations.validSize = false;
      }


      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          console.log(height);
          console.log(width);
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  720 || height != 720 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            this.previvewImgURL = reader.result;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, this.replaceAll(file.name));
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };

    };
    fileUpload.click();
  }

  private uploadFile(formData: FormData) {
    formData.append('id', String(this.existingImage.productID));
    this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.replaceAll(this.file.data.name);
      },
      (err) => {
        this.error = err;
        console.log('Error:', err);
      }
    );


  }

  replaceAll(str) {
    return str.replace(/ /g, "_");
  }

  navigateToProductImages(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(["/seller/product-images/" + pid]);
  }

  colorChanged(event: MatSelectChange) {
    this.selectedColorName = event.source.triggerValue;
  }
  
  saveProductImage() {
    if (this.fileName === '') {
      Swal.fire('Error', 'Image must be uploaded for the product.', 'error');
      return;
    }
    if (!this.fileName.endsWith('.jpg') &&
      !this.fileName.endsWith('.png') &&
      !this.fileName.endsWith('.JPG') &&
      !this.fileName.endsWith('.PNG')) {
      Swal.fire('Error', 'Only .jpg,.JPG,.png,.PNG files allowed for image upload.', 'error');
      return;
    }


    this.existingImage.imagePath =  'Seller/Product/' + JSON.parse(localStorage.getItem('token')).username + '/' + this.existingImage.productID + '/' + this.fileName;
    this.existingImage.color =  this.form.value.color;
    this.existingImage.colorName = this.selectedColorName;

    console.log('Product Image:', this.existingImage);

    this.spinner.show();
    this.productImageService.updateProductImage(this.existingImage).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        this.navigateToProductImages(this.existingImage.productID);
        Swal.fire('Message', res.message, 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    });
  }

  createForm() {
    this.form = this.fb.group({
      color: ['']
    });
  }
  
}
