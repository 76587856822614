import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Color, ProductAttribute, ProductDiscount } from 'src/app/models';
import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { ColorsService } from 'src/app/colors.service';
import { MatSelectChange } from '@angular/material/select';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { ProductDiscountService } from '../product-discount.service';

@Component({
  selector: 'app-add-product-discount',
  templateUrl: './add-product-discount.component.html',
  styleUrls: ['./add-product-discount.component.scss']
})
export class AddProductDiscountComponent implements OnInit {
  form: FormGroup;
  attributeId: any;
  public selectedMoments = [ null, null];
  errors = {
    invalidRange: false,
    invalidUsername: false,
    invalidCoupon: false
  };
  constructor(
              private productDiscountService: ProductDiscountService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( params => {
      // this.attributeId = Number(atob(decodeURIComponent(params["q"])));
      this.attributeId = Number(this.activatedRoute.snapshot.params.id);
    });
  }



  createForm() {
    this.form = this.fb.group({
      username: ['', [  Validators.required, Validators.minLength(10), Validators.maxLength(10)]  ],
      couponCode: ['', [Validators.required, Validators.minLength(6)] ],
      discountPercentage: [, Validators.required ],
    });
  }

  onScheduleDtChange() {
    this.errors.invalidRange = false;
    // console.log(this.selectedMoments[0]);
    // console.log(this.selectedMoments[1]);
  }



  navigateToProductDiscounts(attributeID: number) {
    this.router.navigate(['/seller/product-discount/' + attributeID]);
  }

  convertDate(date: Date) {
    const MM = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    const yyyyMMdd = [date.getFullYear(), (MM > 9 ? '' : '0') + MM, (dd > 9 ? '' : '0') + dd].join('-');
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const hhmmsss = [(hh > 9 ? '' : '0') + hh, (mm > 9 ? '' : '0') + mm, (ss > 9 ? '' : '0') + ss].join(':');

    return yyyyMMdd + ' ' + hhmmsss;
  }

  saveProductDiscount() {

    if (this.selectedMoments[0] === null || this.selectedMoments[1]  === null) {
      this.errors.invalidRange = true;
      return;
    }

    if (String(this.form.value.username).trim().length < 10) {
      this.errors.invalidUsername = true;
      return;
    }
    if (String(this.form.value.couponCode).trim().length < 6) {
      this.errors.invalidCoupon = true;
      return;
    }
    console.log(this.selectedMoments);
    // return;
    // this.selectedMomentsStr
    this.spinner.show();
    const productDiscount: ProductDiscount = {
      username: this.form.value.username,
      couponCode: this.form.value.couponCode,
      attributeID: this.attributeId,
      fromDateTime: this.convertDate(this.selectedMoments[0]),
      toDateTime: this.convertDate(this.selectedMoments[1]),
      used: false,
      discountPercentage: this.form.value.discountPercentage
    };

    console.log(productDiscount);
    this.productDiscountService.addProductDiscount(productDiscount).subscribe(res => {
      this.spinner.hide();
      console.log(res);
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.navigateToProductDiscounts(this.attributeId);
      } else {
        Swal.fire('Error', res.message, 'error');
      }

    }, err => {
      this.spinner.hide();
      console.log('Error ', err);
      if (err instanceof HttpResponse) {
        if (err.status === 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }

    });
  }

}
