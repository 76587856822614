import { Component, OnInit } from '@angular/core';
import { BankDetailsService } from 'src/app/bank-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankDetails } from 'src/app/models';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  bankDetails: BankDetails = null;

  constructor(private bankDetailsService: BankDetailsService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    this.bankDetailsService.getBankDetails(username).subscribe(res => {
      this.bankDetails = res;
      console.log(res);
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

}
