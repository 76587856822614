import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { Stock } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StockService } from 'src/app/stock.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  stocks: Stock[] = [];
  productid: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.stocks);
  displayedColumns: string[] = ['color', 'size', 'weight', 'brand', 'physicalStock', 'actionEdit'];

  constructor(private stockService: StockService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindStocks();
  }

  bindStocks() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe( params => {
      // this.productid = Number(atob(decodeURIComponent(params["q"])));
      this.productid = Number(this.activatedRoute.snapshot.params.id);
      this.stockService.getByProduct(this.productid).subscribe(res => {
        this.stocks = res;
        this.dataSource = new MatTableDataSource<any>(this.stocks);
        this.dataSource.paginator = this.paginator;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      });
    });
  }

  navigateToAddProductStock(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(["/seller/add-stock/"+ pid]);
  }

  navigateToEditProductStock(pid: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(pid)))}
    // };
    this.router.navigate(["/seller/edit-stock/" + pid]);
  }

}
