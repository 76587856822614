import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/users.service';
import { User } from 'src/app/models';
import { BaseApiService } from 'src/app/base-api.service';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  user: any = null;
  baseApiUrl = BaseApiService.baseApiUrl;
  file1: FileUploadModel;

  isInProgress1 = false;
  fileName1 = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  error: string;
  form: FormGroup;
  sliderImg1Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  constructor(private usersService: UsersService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    this.usersService.getUserByUsernameAdmin(username).subscribe(res => {
      this.spinner.hide();
      this.user = res;
      this.form.patchValue({
        name: res.name,
        email: res.email,
        mobile: res.mobile,
        country: res.country,
        state: res.state,
        city: res.city,
        pinCode: res.pinCode,
        about: res.about,
        businessName: res.businessName,
        businessAddress: res.businessAddress
      });
      this.fileName1 = res.businessLogoPath1;
    }, err => {
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: new FormControl({value: ''}, Validators.required),
      mobile: new FormControl({value: '', disabled: true}, Validators.required),
      country: new FormControl({value: '', disabled: true}, Validators.required),
      state: new FormControl({value: '', disabled: true}, Validators.required),
      city: ['', Validators.required],
      pinCode: ['', Validators.required],
      about: ['', Validators.required],
      businessName: ['', Validators.required],
      businessAddress: new FormControl({value: ''}, Validators.required),
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveProfile() {
    // const user: User = {
    //   id: this.user.id,
    //   name: this.form.value.name,
    //   email: this.user.email,
    //   mobile: this.user.mobile,
    //   countryID: this.user.countryID,
    //   stateID: this.user.stateID,
    //   businessLogoPath1: this.fileName1,
    //   qrCode: this.user.qrCode,
    //   city: this.form.value.city,
    //   pinCode: this.form.value.pinCode,
    //   about: this.form.value.about,
    //   businessName: this.form.value.businessName,
    //   businessAddress: this.user.businessAddress,
    //   refundWithin: this.form.value.refundWithin,
    //   isActive: this.user.isActive,
    //   gstRegistrationNumber: this.user.gstRegistrationNumber,
    //   licenseImagePath: this.user.licenseImagePath,
    //   panCardImagePath: this.user.panCardImagePath,
    //   alternateMobile: this.user.alternateMobile,
    //   username: this.user.username,
    //   password: this.user.password,
    //   role: this.user.role,
    //   addedOn: this.user.addedOn,
    //   modifiedBy: this.user.username,
    //   shipmentType: this.user.shipmentType,
    //   sellerType: this.user.sellerType
    // };

    this.user.name = this.form.value.name;
    this.user.businessLogoPath1 = this.fileName1;
    this.user.city = this.form.value.city;
    this.user.pinCode = this.form.value.pinCode;
    this.user.about = this.form.value.about;
    this.user.businessName = this.form.value.businessName;
    this.user.refundWithin = this.form.value.refundWithin;


    this.spinner.show();
    this.usersService.updateUser(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'Profile updated successfully.', 'success');
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    }, err => {
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      
      this.sliderImg1Validations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.sliderImg1Validations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
       mimeType == "image/gif") ) {
        this.sliderImg1Validations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 200) {
        this.sliderImg1Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg1Validations.validDimension =  ( width !=  400 || height != 400 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg1Validations.validDimension || 
              !this.sliderImg1Validations.validSize ||
              !this.sliderImg1Validations.validType) {

                let errorMsg = "";
                if (!this.sliderImg1Validations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.sliderImg1Validations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.sliderImg1Validations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file1 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile1(formData);
          }
        };
      };     
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        }
        const username = JSON.parse(localStorage.getItem('token')).username;
        this.fileName1 = 'Seller/' + username + '/Logo/' +  this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

}
