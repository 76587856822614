import { DomSanitizer, Title } from '@angular/platform-browser';
import { User, TokenAuth, Country, OTPResult, State, UserDTO } from './../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './../users.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator, matchingPasswords } from '../theme/utils/app-validators';
import { AppService } from '../app.service';
import { CountriesService } from '../countries.service';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StatesService } from '../states.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  formOTP: FormGroup;
  displaybtn = true;

  // secretKey = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';
  secretKey = '6Lftsj4UAAAAAFx7LVc8XxTugqEGEJwq5l-wio4_';

  captchaVerified = false;
  countriesSub: Subscription;
  statesSub: Subscription;
  countries: Country[];
  states: State[];
  countryId: string = '';
  stateId: string = '';
  otpSent: boolean = false;
  otpCount: number = 0;
  mobileVerified: boolean = false;
  otpResult: OTPResult;
  imgURL;
  purpose = '';
  otpSentOTPForm = false;
  otpCountOTPForm = 0;
  otpResultOTPForm: OTPResult;
  returnUrl = '';
  private unsubscribeAll$: Subject<void> = new Subject();
  constructor(public appService: AppService, public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar,
    private userService: UsersService, private spinner: NgxSpinnerService, private titleService: Title,
    private countriesService: CountriesService,
    private statesService: StatesService,
    private usersService: UsersService,
    private sanitizer: DomSanitizer) {

      // <iframe width="900" height="506" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      // http://www.youtube.com/embed/oHg5SJYRHA0
      // https://www.youtube.com/embed/Svj1bZz2mXw
      this.imgURL = sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/fBdoiQvGdRM');
  }

  ngOnInit() {
    this.titleService.setTitle('Sign In - ' + this.appService.Data.title);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    this.formOTP = this.formBuilder.group({
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[1-9]{1}[0-9]{9}')])],
      otp: ['', Validators.required]
    });

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      mobile: ['', Validators.pattern('[0-9]{9,20}')],
      otp: ['', Validators.required],
      country: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      email: ['', Validators.compose([Validators.required, emailValidator])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.bindCountries();
  }



  public onRegisterFormSubmit(): void {

    if (this.otpResult === null || this.otpResult === undefined) {
      Swal.fire('Error', 'OTP not sent. Press Send OTP first and enter OTP in the box below.', 'error');
      return;
    }

    const user: User = {
      mobile: this.registerForm.value.mobile,
      name: this.registerForm.value.name,
      email: this.registerForm.value.email,
      username: this.registerForm.value.mobile,
      password: this.registerForm.value.password,
      countryID: +this.registerForm.controls['country'].value,
      stateID: +this.registerForm.controls['state'].value,
      role: 'User',
      isActive: true,
      shipmentType: 'Self Ship',
      logisticType: 'Online Store with Logistics'
    };

    const otp = this.registerForm.value.otp;
    const sessionID = this.otpResult.Details;

    const userDTO: UserDTO = {
      user,
      otp,
      sessionID
    };

    this.spinner.show();
    this.userService.addUser(userDTO).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const auth: TokenAuth = {
          username: this.registerForm.value.mobile,
          password: this.registerForm.value.password,
          grant_type: 'password'
        };


      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });

  }

  doLogin() {
    const auth: TokenAuth = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      grant_type: 'password'
    };

    this.spinner.show();

    this.userService.login(auth).subscribe(resLogin => {
      // console.log(resLogin);
      localStorage.setItem('token',
        JSON.stringify(resLogin));
      this.appService.Data.loggedIn = true;
      this.spinner.hide();
      this.displaybtn = false;

      if (resLogin.role === 'Seller') {
        this.router.navigate(['/seller']);
      } else {
        this.displaybtn = true;
        this.spinner.hide();
        this.snackBar.open('Invalid Username/ Password. Please try again...', 'x',
          { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, error => {
      this.spinner.hide();
      this.snackBar.open('Invalid Username/ Password. Please try again...', 'x',
        { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null) {
      this.userService.verifyCaptcha(captchaResponse, this.secretKey).subscribe(res => {
        if (res) {
          this.captchaVerified = true;
        } else {
          this.captchaVerified = false;
        }
      });
    } else {
      this.captchaVerified = false;
    }
  }

  isDisabledSendOTP() {
    return this.registerForm.value.mobile == '' || this.otpSent == true;
  }
  isDisabledResendOTP() {
    return this.registerForm.value.mobile == ''
      || this.otpCount == 2 || this.otpSent == false;
  }
  isDisabledVerifyOTP() {
    return this.registerForm.value.mobile == ''
      || this.registerForm.value.otp == '' || this.mobileVerified == true;
  }


  verifyOTP() {
    const otp = this.registerForm.value.otp;
    const sessionID = this.otpResult.Details;

    this.spinner.show();
    this.usersService.verifyOTP(otp, sessionID).subscribe(res => {
      this.spinner.hide();
      if (res.Status.indexOf('Success') != -1 &&
        res.Details.indexOf('OTP Matched') != -1) {
        this.mobileVerified = true;
        Swal.fire('Message', 'Mobile No. Verified successfully.', 'success');
      } else {
        Swal.fire('Error', 'OTP not matched. Please enter correct OTP...', 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  sendOTP() {
    const country = this.countries.filter(c => c.id == this.registerForm.controls['country'].value)[0];
    const phoneCode = country.phoneCode;
    const mobileNumber = phoneCode + this.registerForm.value.mobile;

    this.spinner.show();

    this.usersService.sendOTP(mobileNumber).subscribe(res => {
      this.otpResult = res;
      this.otpSent = true;
      this.otpCount++;
      this.spinner.hide();
      Swal.fire('Message', 'OTP has been sent to your Mobile Number. Kindly enter it in the box below.', 'success');
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  bindCountries() {
    this.spinner.show();
    this.countriesSub = this.countriesService.getCountries().subscribe(res => {
      this.countries = res;
      this.registerForm.patchValue({
        country: res.filter(c => c.id === 101)[0].id
      });
      this.spinner.hide();
      this.bindStates();
    }, err => {
      this.spinner.hide();
    });
  }

  bindStates() {
    if (this.statesSub != null) {
      this.statesSub.unsubscribe();
    }
    this.spinner.show();
    this.statesSub = this.statesService.getByCountry(+this.registerForm.controls['country'].value).subscribe(res => {
      this.states = res;
      this.registerForm.patchValue({
        state: res.filter(s => s.id === 15)[0].id
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  isDisabledSendOTPFormOTP() {
    return this.formOTP.value.mobile === '' || this.otpSent === true;
  }
  isDisabledResendOTPFormOTP() {
    return this.formOTP.value.mobile === ''
        || this.otpCount === 2 || this.otpSent === false;
  }

  async sendOTPFormOTP() {
    // tslint:disable-next-line:no-string-literal
    // const country = this.countries.filter(c => c.id == this.registerForm.controls['country'].value)[0];
    // const phoneCode = country.phoneCode;
    // const mobileNumber = phoneCode + this.registerForm.value.mobile;
    const mobileNumber = this.formOTP.value.mobile;

    await this.spinner.show();

    this.usersService.sendOTP(mobileNumber).pipe(takeUntil(this.unsubscribeAll$)).subscribe(async res => {
      this.otpResult = res;
      this.otpSent = true;
      this.otpCount++;
      await this.spinner.hide();
      // Swal.fire('Message', 'OTP has been sent to your Mobile Number. Kindly enter it in the box below.', 'success');
    }, async err => {
      await this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  async loginOrRegister() {
    const mobile = this.formOTP.value.mobile;
    const otp = this.formOTP.value.otp;
    const sessionID = this.otpResult.Details;

    this.spinner.show();
    this.usersService.loginOrRegister(mobile, otp, sessionID)
        .subscribe(async res => {
          await this.spinner.hide();
          console.log('LoginOrRegister Result: ', res);
          if (res.added) {
            localStorage.setItem('token', JSON.stringify(res.token));
            this.appService.Data.loggedIn = true;
            if (this.purpose && this.purpose === 'checkout') {
              await this.router.navigate(['/checkout']);
            } else if (this.returnUrl) {
              await this.router.navigate([decodeURIComponent(this.returnUrl)]);
            } else {
              await this.router.navigate(['/seller']);
            }
          } else {
            await Swal.fire('Error', res.message, 'error');
          }
        }, async err => {
          await this.spinner.hide();
          await Swal.fire('Error', 'Error occured. Please try again...', 'error');
        });
  }

  FAQArrOdd=[{
    question:'Q1. What are the benefits of having an online store?',
    answer:`It is impossible to survive on traditional ways in this world that is ruled by technology and advancements. In the field of business especially, you can’t rely on old school methods to gain customers anymore. It is important to accept and adjust with the modern preferences to stay ahead in the game to retain your old consumers and attract new ones. Having an online store means more visibility and global access. It helps you provide an improved client service through greater flexibility and increased professionalism.
    Having an online presence is also a more efficient and cost effective way for people who can’t spend their fortunes to start an enterprise or set up a physical store to sell their goods.`,
    },
    {
      question:'Q3. How to become a seller?',
      answer:`Visit our website www.jhelumcart.com and sign up as a seller. It is as easy as signing up on any social media platform. Once your profile is formed, you get a free trial period of 14 days to build your market online and then you can continue to sell on Jhelumcart.`,
    },{
      question:'Q5. Do I need to pay any additional taxes to start an online store?',
      answer:`You are completely exempted from paying any additional taxes if you wish to make a presence online. You are not liable to pay anything other than a monthly subscription fee to Jhelum cart and run your business efficiently and hassle-free.`
    },
    {
      question:'Q7. When do the sellers receive payments?',
      answer:`The payments are processed after every six days and transferred to respective sellers’ bank accounts. Usually, we do it every Monday but the payment for orders of that particular day is added to the next week’s list and processed with them. However, if a seller is able to sell 25% of his stock value on our website before the completion of a week’s time, we speed up the payment process and ensure to make payments accordingly so that the seller can restock the items.`
    },{
      question:'Q9. How do I get to know how my online store is doing?',
      answer:`You can easily get to know about your sales and consumer behavior. You can easily check how many people follow your store and are engaged with you. We provide you complete analytics by gathering data from all areas that have an impact on your online store and use this information to understand the trends and the shift in consumers' behavior. This helps you to make data-driven decisions that will drive more online sales.`
    }
  ];

  FAQArrEven=[
    {
      question:'Q2. Why should I join Jhelum cart?',
      answer:`The trade and commerce of Kashmir is on top of the list to bear the brunt of any kind of un-stability or unrest in Kashmir. The motive of Jhelum cart is to support local business and stabilize the economy of Kashmir. Through our website, we help local sellers to reach worldwide audience. Considering the curtailments of this disputed territory, the app is designed to run easily on 2G speed where you can easily set up and manage your online store. We provide you free of cost assistance and services that you can’t get on any other platform. This includes product content creation, post-processing of product images and real time seller support. Moreover, you are offered technical solutions including an easy to use seller dashboard, data driven solutions as well as logistic services. 
      `
    },{
      question:'Q4. What are the documents required to register as a seller on Jhelum cart?',
      answer:`In order to sign up with Jhelum cart, you need to fill in with some of your personal details and complete business details. You are also required to provide a GST number or a legally authorized registration number. We also need to know the details of your bank account in the name of your business to receive all the payments.`
    },{
      question:'Q6. Who takes the payment for the orders?',
      answer:`The customer can either make direct payments into the bank account of Jhelum cart or pay COD. Either ways, the payment of the product along with its delivery charges first come to us. After deducting the delivery charges, we regulate the payment which is eventually transferred to the prospective sellers.`
    },{
      question:'Q8. How frequently can I upload the products or update my online store?',
      answer:`You can upload new products or update the stock any time after logging in with your credentials on the seller app. It is both time saving and easy to use.`
    }

  ];
}