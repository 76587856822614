import { Component, OnInit, ViewContainerRef, AfterViewChecked, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { interval, timer, Subject, Observable, of } from 'rxjs';
import { map, takeUntil, startWith, take, filter } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColorPickerService } from 'ngx-color-picker';
@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit, OnDestroy {

  menuItemsLinks = [];
  productLinks = [];
  loading: boolean;
  prod: any;
  private unsubscribeAll$: Subject<void> = new Subject();

  productForm: FormGroup;
  name: FormControl;
  category: FormControl;
  // gstrate: FormControl;
  addnew = false;

  applyToChoosed = 'container';
  applyingTo = [{ value: 'container', viewView: 'Container' },
  { value: 'header', viewView: 'Header Content' },
  { value: 'main', viewView: 'Main Content' }];

  public bgColor = '#ececec';
  public fgColor = '#0000ff';

  fonts = [
    'Arial', 'sans-serif', 'Times New Roman', 'Helvetica', 'Times', 'Courier New', 'Verdana', 'Courier', 'Arial Narrow',
    'Candara', 'Geneva', 'Calibri', 'monospace', 'Lucidatypewriter', 'cursive', 'Comic Sans', 'fantasy', 'Trebuchet MS',
    'Noto Sans', 'Georgia', 'Impact',
    'Calibri', 'Cambria', 'Garamond', 'Perpetua', 'Monaco', 'Didot', 'Brush Script MT', 'Lucida Bright', 'Copperplate'
  ];

  settings = {
    container: {
      color: {
        bg: '',
        fg: ''
      },
      font: {
        family: '',
        size: 10
      }
    },
    header: {
      color: {
        bg: '',
        fg: ''
      },
      font: {
        family: '',
        size: 10
      }
    },
    main: {
      color: {
        bg: '',
        fg: ''
      },
      font: {
        family: '',
        size: 10
      }
    }
  };


  fontFamilyChoosed = this.fonts[0];
  fontSizeChoosed = 32;
  fontStyleChoosed = ['Normal'];

  fontStyleCtrl = new FormControl();
  fontStyles: string[] = ['Normal', 'Italic', 'Bold', 'Underline'];

  // tslint:disable-next-line:max-line-length
  constructor(private activedRoute: ActivatedRoute, private router: Router, public vcRef: ViewContainerRef) {
    this.name = new FormControl('', [Validators.required]);
    this.category = new FormControl('', [Validators.required]);
    this.productForm = new FormGroup({
      name: this.name,
      category: this.category
    });

    this.fonts = this.fonts.map(f => {
      return f.charAt(0).toUpperCase() + f.slice(1);
    });
    this.fonts.sort();
  }



  public colorSelected(event: string, data: any): void {
    // console.log(event, data);
    console.log(this.bgColor);
    console.log(this.fgColor);
    console.log(this.applyToChoosed);
    switch (this.applyToChoosed) {
      case 'container':
        this.settings.container.color.bg = this.bgColor;
        this.settings.container.color.fg = this.fgColor;
        break;
      case 'header':
        this.settings.header.color.bg = this.bgColor;
        this.settings.header.color.fg = this.fgColor;
        break;
      case 'main':
        this.settings.main.color.bg = this.bgColor;
        this.settings.main.color.fg = this.fgColor;
        break;
    }

  }

  applyingToSelected(val) {
    console.log(val);
    console.log(this.applyToChoosed);
  }

  fontStyleSelected(val) {
    console.log(val);
    console.log(this.fontStyleChoosed);


  }

  fontFamilySelected(val) {
    console.log(val);
    console.log(this.fontFamilyChoosed);

    switch (this.applyToChoosed) {
      case 'container':
        this.settings.container.font.family = this.fontFamilyChoosed;
        break;
      case 'header':
        this.settings.header.font.family = this.fontFamilyChoosed;
        break;
      case 'main':
        this.settings.main.font.family = this.fontFamilyChoosed;
        break;
    }
  }

  onSizeChanged(val) {
    // console.log(val);
    // console.log(this.fontSizeChoosed);
    switch (this.applyToChoosed) {
      case 'container':
        this.settings.container.font.size = this.fontSizeChoosed;
        break;
      case 'header':
        this.settings.header.font.size = this.fontSizeChoosed;
        break;
      case 'main':
        this.settings.main.font.size = this.fontSizeChoosed;
        break;
    }
  }


  ngOnInit() {

    this.settings.container.color.bg = this.bgColor;
    this.settings.container.color.fg = this.fgColor;
    this.activedRoute.queryParams.pipe(takeUntil(this.unsubscribeAll$)).subscribe(queryParams => {
      // tslint:disable-next-line:no-string-literal
      this.addnew = queryParams['addnew'];
      if (this.addnew === undefined) {
        console.log('oh yah');
        this.activedRoute.params.pipe(takeUntil(this.unsubscribeAll$)).subscribe(params => {
          // this.getmenuItems(params.id as number);
        });
      }

    });

    console.log('CustomizeComponent: ngOnInit');

  }



  parentSelected(value) {

  }
  ngOnDestroy() {
    console.log('CustomizeComponent: ngOnDestroy');
    // this.unsubscribeAll$.next();
    // this.unsubscribeAll$.unsubscribe();
  }




}
