import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SellerAuthGuard } from '../seller-auth.guard';
import { SellerHomeComponent } from './seller-home/seller-home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { MessagesComponent } from './messages/messages.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { GenerateQrCodeComponent } from './generate-qr-code/generate-qr-code.component';
import { SellerDashboardComponent } from './seller-dashboard/seller-dashboard.component';
import { ProductsComponent } from './products/products.component';
import { ProductAttributesComponent } from './product-attributes/product-attributes.component';
import { AddProductAttributeComponent } from './add-product-attribute/add-product-attribute.component';
import { EditProductAttributeComponent } from './edit-product-attribute/edit-product-attribute.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { StockRegisterComponent } from './stock-register/stock-register.component';
import { OrdersComponent } from './orders/orders.component';
import { StocksComponent } from './stocks/stocks.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { EditStockComponent } from './edit-stock/edit-stock.component';
import { EditBankDetailsComponent } from './edit-bank-details/edit-bank-details.component';
import { AddBankDetailsComponent } from './add-bank-details/add-bank-details.component';
import { AddProductImageComponent } from './add-product-image/add-product-image.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { EditProductImageComponent } from './edit-product-image/edit-product-image.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SlidersComponent } from './sliders/sliders.component';
import { AddSliderComponent } from './add-slider/add-slider.component';
import { EditSliderComponent } from './edit-slider/edit-slider.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { PincodesComponent } from './pincodes/pincodes.component';
import { AddPincodeComponent } from './add-pincode/add-pincode.component';
import { EditPincodeComponent } from './edit-pincode/edit-pincode.component';
import { AddPincodeSlabComponent } from './add-pincode-slab/add-pincode-slab.component';
import { EditPincodeSlabComponent } from './edit-pincode-slab/edit-pincode-slab.component';
import { PincodeSlabsComponent } from './pincode-slabs/pincode-slabs.component';
import { AddProductDiscountComponent } from '../add-product-discount/add-product-discount.component';
import { ProductDiscountComponent } from '../product-discounts/product-discounts.component';
import { EditProductDiscountComponent } from '../edit-product-discount/edit-product-discount.component';
import { CustomizeModule } from '../customize/customize.module';
import { SeasonalDiscountsComponent } from './seasonal-discounts/seasonal-discounts.component';
import { AddSeasonalDiscountComponent } from './add-seasonal-discount/add-seasonal-discount.component';
import { EditSeasonalDiscountComponent } from './edit-seasonal-discount/edit-seasonal-discount.component';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { AddCustomOrderComponent } from './add-custom-order/add-custom-order.component';


const routes: Routes = [
  {path: '', canActivate: [SellerAuthGuard], component: SellerHomeComponent,
   children: [
    //  {path:'', component: SellerDashboardComponent},
    {path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
    {path: 'products', component: ProductsComponent},
    {path: 'product-attributes/:id', component: ProductAttributesComponent},
    {path: 'add-product-attribute/:id', component: AddProductAttributeComponent},
    {path: 'edit-product-attribute/:id', component: EditProductAttributeComponent},
    {path: 'add-product', component: AddProductComponent},
    {path: 'add-stock/:id', component: AddStockComponent},
    {path: 'edit-stock/:id', component: EditStockComponent},
    {path: 'edit-product/:id', component: EditProductComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'profile-edit', component: ProfileEditComponent},
    {path: 'stock-register', component: StockRegisterComponent},
    {path: 'orders', component: OrdersComponent},
    {path: 'order-details/:id', component: OrderDetailsComponent},
    {path: 'messages', component: MessagesComponent},
    {path: 'change-password', component: ChangePasswordComponent},
    {path: 'bank-details', component: BankDetailsComponent},
    {path: 'generate-qr-code', component: GenerateQrCodeComponent},
    {path: 'stocks/:id', component: StocksComponent},
    {path: 'add-bank-details', component: AddBankDetailsComponent},
    {path: 'edit-bank-details', component: EditBankDetailsComponent},
    {path: 'product-images/:id', component: ProductImagesComponent},
    {path: 'add-product-image/:id', component: AddProductImageComponent},
    {path: 'edit-product-image/:id', component: EditProductImageComponent},
    {path: 'notifications', component: NotificationsComponent},
    {path: 'sliders', component: SlidersComponent},
    {path: 'add-slider', component: AddSliderComponent},
    {path: 'edit-slider/:id', component: EditSliderComponent},
    {path: 'pincodes', component: PincodesComponent},
    {path: 'add-pincode', component: AddPincodeComponent},
    {path: 'edit-pincode/:id', component: EditPincodeComponent},
    {path: 'pincode-slabs/:id', component: PincodeSlabsComponent},
    {path: 'add-pincode-slab/:id', component: AddPincodeSlabComponent},
    {path: 'edit-pincode-slab/:id', component: EditPincodeSlabComponent},
    {path: 'product-discount/:id', component: ProductDiscountComponent},
    {path: 'add-product-discount/:id', component: AddProductDiscountComponent},
    {path: 'edit-product-discount/:id/:aid', component: EditProductDiscountComponent},
    {path: 'customize', loadChildren: () => CustomizeModule},
    {path: 'seasonal-discounts', component: SeasonalDiscountsComponent},
    {path: 'add-seasonal-discount', component: AddSeasonalDiscountComponent},
    {path: 'edit-seasonal-discount/:id', component: EditSeasonalDiscountComponent},
    {path: 'custom-orders', component: CustomOrdersComponent},
    {path: 'custom-order-details/:id', component: CustomOrderDetailsComponent},
    {path: 'add-custom-order', component: AddCustomOrderComponent}
   ]},
  //  {path: 'seller-dashboard', component: SellerDashboardComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SellerRoutingModule { }
