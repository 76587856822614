import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { SellerPincode } from './models';

@Injectable({
  providedIn: 'root'
})
export class SellerPincodesService {

  constructor(private http: HttpClient) { }

  public getShippingPincodes(fromPincode: string, toPincode: string,
    pageNumber: number, pageSize: number, sort: string): Observable<any> {
      const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/SellerPincode/GetSellerPincodes?username=' + username + 
    '&fromPincode=' +
    fromPincode + '&toPincode=' + toPincode + '&pageNumber=' + pageNumber + 
    '&pageSize=' + pageSize + '&sort=' + sort;

    return this.http.get<any>(url);
  }

  public getShippingPincode(id: number): Observable<SellerPincode> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincode/GetSellerPincode/' + id;

    return this.http.get<SellerPincode>(url);
  }

  public addShippingPincode(shippingPincode: SellerPincode): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincode/AddSellerPincode';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, shippingPincode, {headers});
  }

  public updateShippingPincode(shippingPincode: SellerPincode): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincode/UpdateSellerPincode';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, shippingPincode, {headers});
  }

  public deleteShippingPincode(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/SellerPincode/DeletePincode/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }
}
