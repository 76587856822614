import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Color, ProductAttribute, SeasonalDiscount } from 'src/app/models';
import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { ColorsService } from 'src/app/colors.service';
import { MatSelectChange } from '@angular/material/select';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { ProductDiscountService } from 'src/app/product-discount.service';
import { SeasonalDiscountService } from 'src/app/seasonal-discount.service';

@Component({
  selector: 'app-edit-seasonal-discount',
  templateUrl: './edit-seasonal-discount.component.html',
  styleUrls: ['./edit-seasonal-discount.component.scss']
})
export class EditSeasonalDiscountComponent implements OnInit {

  form: FormGroup;
  seasonalDiscount: SeasonalDiscount;
  discountId: any;

  public selectedMoments = [ null, null];
  errors = {
    invalidRange: false,
    invalidCoupon: false
  };
  constructor(
              private seasonalDiscountService: SeasonalDiscountService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
  }

  ngOnInit(): void {
    this.bindDiscount();
  }

  onScheduleDtChange() {
    console.log(this.selectedMoments[0]);
    console.log(this.selectedMoments[1]);
  }


  bindDiscount() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe( params => {
      // this.productid = Number(atob(decodeURIComponent(params["q"])));
      this.discountId = Number(this.activatedRoute.snapshot.params.id);
      this.seasonalDiscountService.getSeasonalDiscount(this.discountId).subscribe( res => {
        this.spinner.hide();
        console.log(res);


        const fromDateTime = res.fromDateTime;
        const fromYYYY = fromDateTime.substring(0, 4);
        const fromMM = fromDateTime.substring(5, 7);
        const fromdd = fromDateTime.substring(8, 10);

        const fromhh = fromDateTime.substring(11, 13);
        const frommm = fromDateTime.substring(14, 16);
        const fromss = fromDateTime.substring(17, 19);

        const toDateTime = res.toDateTime;
        const toYYYY = toDateTime.substring(0, 4);
        const toMM = toDateTime.substring(5, 7);
        const todd = toDateTime.substring(8, 10);

        const tohh = toDateTime.substring(11, 13);
        const tomm = toDateTime.substring(14, 16);
        const toss = toDateTime.substring(17, 19);


        // console.log(fromYYYY);
        // console.log(fromMM);
        // console.log(fromdd);
        // console.log(fromhh);
        // console.log(frommm);
        // console.log(fromss);

        const from = new Date(Number(fromYYYY), Number(fromMM) - 1, Number(fromdd), Number(fromhh), Number(frommm), Number(fromss), 0);
        const to = new Date(Number(toYYYY), Number(toMM) - 1, Number(todd), Number(tohh), Number(tomm), Number(toss), 0);
        this.selectedMoments = [from, to];
        this.form.patchValue({
          couponCode: res.couponCode,
          discountPercentage: res.discountPercentage
        });
        this.seasonalDiscount = res;

      }, err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
    });

  }

  createForm() {
    this.form = this.fb.group({
      couponCode: ['', [Validators.required, Validators.minLength(6)] ],
      discountPercentage: ['', Validators.required ],
    });
  }

  convertDate(date: Date) {
    const MM = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    const yyyyMMdd = [date.getFullYear(), (MM > 9 ? '' : '0') + MM, (dd > 9 ? '' : '0') + dd].join('-');
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const hhmmsss = [(hh > 9 ? '' : '0') + hh, (mm > 9 ? '' : '0') + mm, (ss > 9 ? '' : '0') + ss].join(':');

    return yyyyMMdd + ' ' + hhmmsss;
  }


  saveSeasonalDiscount() {

    if (this.selectedMoments[0] === null || this.selectedMoments[1]  === null) {
      this.errors.invalidRange = true;
      return;
    }

    if (String(this.form.value.couponCode).trim().length < 6) {
      this.errors.invalidCoupon = true;
      return;
    }
    console.log(this.selectedMoments);

    this.spinner.show();

    this.seasonalDiscount.couponCode = this.form.value.couponCode;
    this.seasonalDiscount.fromDateTime = this.convertDate(this.selectedMoments[0]);
    this.seasonalDiscount.toDateTime = this.convertDate(this.selectedMoments[1]);
    this.seasonalDiscount.discountPercentage = this.form.value.discountPercentage


    console.log(this.seasonalDiscount);
    this.seasonalDiscountService.updateSellerDiscount(this.seasonalDiscount).subscribe(res => {
      this.spinner.hide();
      console.log(res);
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.navigateToSeasonalDiscounts();
      } else {
        Swal.fire('Error', res.message, 'error');
      }

    }, err => {
      this.spinner.hide();
      console.log('Error ', err);
      if (err instanceof HttpResponse) {
        if (err.status === 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }

    });
  }

  navigateToSeasonalDiscounts() {
    this.router.navigate(['/seller/seasonal-discounts']);
  }

}
