import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BankDetailsService } from 'src/app/bank-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankDetails } from 'src/app/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-bank-details',
  templateUrl: './edit-bank-details.component.html',
  styleUrls: ['./edit-bank-details.component.scss']
})
export class EditBankDetailsComponent implements OnInit {
  bankDetails: BankDetails = null;
  form: FormGroup;
  constructor(private bankDetailsService: BankDetailsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    this.bankDetailsService.getBankDetails(username).subscribe(res => {
      this.bankDetails = res;
      this.form.patchValue({
        name: res.name,
        accountNumber: res.accountNumber,
        ifscCode: res.ifscCode
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      accountNumber: ['', Validators.required],
      ifscCode: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveBankDetails() {
    this.spinner.show();
    this.bankDetails.name = this.form.value.name;
    this.bankDetails.accountNumber = this.form.value.accountNumber;
    this.bankDetails.ifscCode = this.form.value.ifscCode;

    this.bankDetailsService.updateBankDetails(this.bankDetails).subscribe(res => {
      this.spinner.hide();
      if (res) {
        Swal.fire('Message', 'Bank Details updated successfully.', 'success');
        this.router.navigate(['/seller/bank-details']);
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    })
  }

}
