import { Component, OnInit, OnDestroy } from '@angular/core';
import { Country, State, User, TokenAuth, OTPResult, UserDTO } from '../models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '../users.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords } from '../theme/utils/app-validators';
import { CountriesService } from '../countries.service';
import { StatesService } from '../states.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-seller-register',
  templateUrl: './seller-register.component.html',
  styleUrls: ['./seller-register.component.scss']
})
export class SellerRegisterComponent implements OnInit, OnDestroy {
  countries: Country[];
  states: State[];
  countryId: string = '';
  stateId: string = '';
  otpSent: boolean = false;
  otpCount: number = 0;
  mobileVerified: boolean = false;
  usernameLogin: string;
  passwordLogin: string;
  form: FormGroup;
  saveSub: Subscription = null;
  loginSub: Subscription = null;
  countriesSub: Subscription;
  statesSub: Subscription;
  verifyCaptchaSub: Subscription = null;
  otpResult: OTPResult;

  secretKey = '6Lftsj4UAAAAAFx7LVc8XxTugqEGEJwq5l-wio4_';

  captchaVerified = false;

  constructor(private usersService: UsersService,
              private fb: FormBuilder,
              private spinner: NgxSpinnerService,
              public appService: AppService,
              private countriesService: CountriesService,
              private statesService: StatesService,
              private snackBar: MatSnackBar,
              private router: Router,
              private titleService: Title) {
    // initialize the form
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{10}')])],
      otp: ['', Validators.required],
      country: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      businessName: ['', Validators.required],
      businessAddress: ['', Validators.required],
      about: ['', Validators.required],
      gstRegistrationNumber: [''],
      sellerType: ['', Validators.required],
      city: new FormControl({ value: 'Srinagar', disabled: true }),
      pincode: ['', Validators.pattern('[0-9]{5,8}')],
      username: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9]{3,20}')])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      shipmentType: ['', Validators.required],
      logisticType: ['', Validators.required],
    }, { validator: matchingPasswords('password', 'confirmPassword') });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Seller Registration - JhelumCart Online Pvt. Ltd.')
    this.bindCountries();
  }

  bindCountries() {
    this.spinner.show();
    this.countriesSub = this.countriesService.getCountries().subscribe(res => {
      this.countries = res;
      this.form.patchValue({
        country: res.filter(c => c.id === 101)[0].id
      });
      this.spinner.hide();
      this.bindStates();
    }, err => {
      this.spinner.hide();
    });
  }

  onCountrySelected() {
    this.bindStates();
  }

  bindStates() {
    if (this.statesSub != null ) {
      this.statesSub.unsubscribe();
    }
    this.spinner.show();
    this.statesSub = this.statesService.getByCountry(+this.form.controls['country'].value).subscribe(res => {
      this.states = res;
      this.form.patchValue({
        state: res.filter(s => s.id === 15)[0].id
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null) {
      if (this.verifyCaptchaSub != null) {
        this.verifyCaptchaSub.unsubscribe();
      }
      this.verifyCaptchaSub = this.usersService.verifyCaptcha(captchaResponse, this.secretKey).subscribe(res => {
        if (res) {
          this.captchaVerified = true;
        } else {
          this.captchaVerified = false;
        }
      });
    } else {
      this.captchaVerified = false;
    }
  }

  isDisabledSendOTP(){
    return this.form.value.mobile == '' || this.otpSent == true;
  }
  isDisabledResendOTP(){
    return this.form.value.mobile == ''
    || this.otpCount == 2 || this.otpSent == false;
  }
  isDisabledVerifyOTP(){
    return this.form.value.mobile == ''
    || this.form.value.otp == '' || this.mobileVerified == true;
  }

  sendOTP() {
    const country = this.countries.filter(c => c.id == this.form.controls['country'].value)[0];
    const phoneCode = country.phoneCode;
    const mobileNumber = phoneCode + this.form.value.mobile;
    
    this.spinner.show();
    this.usersService.sendOTP(mobileNumber).subscribe(res => {
      this.otpResult = res;
      this.otpSent = true;
      this.otpCount++;
      this.spinner.hide();
      Swal.fire('Message', 'OTP has been sent to your Mobile Number. Kindly enter it in the box below.', 'success');
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }
  verifyOTP() {
    const otp = this.form.value.otp;
    const sessionID = this.otpResult.Details;

    this.spinner.show();
    this.usersService.verifyOTP(otp, sessionID).subscribe(res => {
      this.spinner.hide();
      if (res.Status.indexOf('Success') != -1 &&
          res.Details.indexOf('OTP Matched') != -1) {
            this.mobileVerified = true;
            Swal.fire('Message', 'Mobile No. Verified successfully.', 'success');
          } else {
            Swal.fire('Error', 'OTP not matched. Please enter correct OTP...', 'error');
          }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  removeSpaces(str) {
    return str.replace(/ /g, '');
  }

  register() {

    if (this.otpResult === null || this.otpResult === undefined) {
      Swal.fire('Error', 'OTP not sent. Press Send OTP first and enter OTP in the box below.', 'error');
      return;
    }

    const user: User = {
      id: '',
      role: 'Seller',
      name: this.form.value.name,
      email: this.form.value.email,
      mobile: this.form.value.mobile,
      username: this.removeSpaces(this.form.value.username),
      password: this.form.value.password,
      countryID: +this.form.controls['country'].value,
      stateID: +this.form.controls['state'].value,
      city: this.form.controls['city'].value,
      businessName: this.form.value.businessName,
      businessAddress: this.form.value.businessAddress,
      about: this.form.value.about,
      businessLogoPath1: '',
      businessLogoPath2: '',
      businessLogoPath3: '',
      businessLogoPath4: '',
      registrationDocumentImagePath: '',
      aadharCardImagePath: '',
      panCardImagePath: '',
      pinCode: this.form.value.pincode,
      refundWithin: 0,
      sellerType: this.form.value.sellerType,
      gstRegistrationNumber: this.form.value.gstRegistrationNumber,
      isActive: false,
      shipmentType: this.form.value.shipmentType,
      logisticType: this.form.value.logisticType
    };

    const otp = this.form.value.otp;
    const sessionID = this.otpResult.Details;

    const userDTO: UserDTO = {
      user,
      otp,
      sessionID
    };

    this.spinner.show();
    this.saveSub = this.usersService.addUser(userDTO).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const auth: TokenAuth = {
          username: this.form.value.username,
          password: this.form.value.password,
          grant_type: 'password'
        };

        this.loginSub = this.usersService.login(auth).subscribe(resLogin => {
          localStorage.setItem('token',
            JSON.stringify(resLogin));
          this.appService.Data.loggedIn = true;
          this.router.navigate(['/seller']);
        });
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  ngOnDestroy() {
    if (this.saveSub != null) {
      this.saveSub.unsubscribe();
    }
    if (this.verifyCaptchaSub != null) {
      this.verifyCaptchaSub.unsubscribe();
    }
    if (this.loginSub != null) {
      this.loginSub.unsubscribe();
    }
    this.countriesSub.unsubscribe();
    this.statesSub.unsubscribe();
  }

  omitSpecialChars(event) {
     if (Number(event.keyCode) === 32) {
      //  alert('You can\'t put);
      // this.form.value.username = '';
      this.form.patchValue({
        username: this.removeSpaces(this.form.value.username),
      });
      // alert('Spaces are not allowed in username');
      Swal.fire('Warning', 'Spaces are not allowed in username', 'warning');
     }
    //  return((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }
}
