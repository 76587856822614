import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SellerPincodeSlabsService } from 'src/app/seller-pincode-slabs.service';

@Component({
  selector: 'app-add-pincode-slab',
  templateUrl: './add-pincode-slab.component.html',
  styleUrls: ['./add-pincode-slab.component.scss']
})
export class AddPincodeSlabComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addShippingPincodeSub: Subscription;
  constructor(private shippingPincodeSlabService: SellerPincodeSlabsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      fromWeight: ['', Validators.required],
      toWeight: ['', Validators.required],
      shippingCharges: ['', Validators.required],
      deliveredWithin: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincodeSlab() {
    this.spinner.show();
    const shippingPincodeSlab = {
      pincodeID: this.activatedRoute.snapshot.params.id,
      fromWeight: this.form.value.fromWeight,
      toWeight: this.form.value.toWeight,
      shippingCharges: this.form.value.shippingCharges,
      deliveryTime: this.form.value.deliveredWithin
    };
    this.addShippingPincodeSub = this.shippingPincodeSlabService.addSlab(shippingPincodeSlab).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/seller/pincode-slabs/' + this.activatedRoute.snapshot.params.id]);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.addShippingPincodeSub.unsubscribe();
  }

}
