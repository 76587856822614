import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductImage } from './models';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductImageService {

  constructor(private http: HttpClient) { }

  public addProductImage(productImage: ProductImage): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductImage/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, productImage, {headers});
  }

  public updateProductImage(productImage: ProductImage): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductImage/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, productImage, {headers});
  }

  public deleteProductImage(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductImage/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }

  public getProductImages(productId: number): Observable<ProductImage[]> {
    const url = BaseApiService.baseApiUrl + 'api/ProductImage/GetProductImages?productId=' + productId;

    return this.http.get<ProductImage[]>(url);
  }

  public getProductImage(id: number): Observable<ProductImage> {
    const url = BaseApiService.baseApiUrl + 'api/ProductImage/GetProductImage/' + id;

    return this.http.get<ProductImage>(url);
  }
}
