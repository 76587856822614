import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from 'src/app/stock.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { BankDetailsService } from 'src/app/bank-details.service';
import { BankDetails } from 'src/app/models';

@Component({
  selector: 'app-add-bank-details',
  templateUrl: './add-bank-details.component.html',
  styleUrls: ['./add-bank-details.component.scss']
})
export class AddBankDetailsComponent implements OnInit {

  form: FormGroup;
  constructor(private bankDetailsService: BankDetailsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      accountNumber: ['', Validators.required],
      ifscCode: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveBankDetails() {
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    this.spinner.show();
    const bankDetails: BankDetails = {
      name: this.form.value.name,
      accountNumber: this.form.value.accountNumber,
      ifscCode: this.form.value.ifscCode,
      username: username
    };

    this.bankDetailsService.addBankDetails(bankDetails).subscribe(res => {
      this.spinner.hide();
      if (res) {
        Swal.fire('Message', 'Bank Details added successfully.', 'success');
        this.router.navigate(['/seller/bank-details']);
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    })
  }

}
