import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PaymentOrder, User } from 'src/app/models';
import { Subject, interval } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification.service';
import { AppService } from 'src/app/app.service';
import { MatSidenav } from '@angular/material/sidenav';
import { FileUploadModel } from 'src/app/models/models';
import { HttpErrorResponse } from '@angular/common/http';
import { OrdersService } from 'src/app/orders.service';
declare var Razorpay: any;
@Component({
  selector: 'app-seller-home',
  templateUrl: './seller-home.component.html',
  styleUrls: ['./seller-home.component.scss']
})
export class SellerHomeComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  destroyed = new Subject<void>();

  profileLocked = false;
  licenseExpired = false;
  subscriptionModel = '';
  rentPeriodInMonths = 1;
  rentAmount = 0;
  isInProgress = false;

  file1: FileUploadModel;
  file2: FileUploadModel;
  file3: FileUploadModel;
  file4: FileUploadModel;
  fileRegistration: FileUploadModel;
  fileAadharCard: FileUploadModel;
  filePanCard: FileUploadModel;
  isInProgress1 = false;
  isInProgress2 = false;
  isInProgress3 = false;
  isInProgress4 = false;
  isInProgressRegistration = false;
  isInProgressAadharCard = false;
  isInProgressPanCard = false;
  fileName1 = '';
  fileName2 = '';
  fileName3 = '';
  fileName4 = '';
  fileNameRegistration = '';
  fileNameAadharCard = '';
  fileNamePanCard = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  uploadResponse2 = { status: '', message: '', filePath: '' };
  uploadResponse3 = { status: '', message: '', filePath: '' };
  uploadResponse4 = { status: '', message: '', filePath: '' };
  uploadResponseRegistration = { status: '', message: '', filePath: '' };
  uploadResponseAadharCard = { status: '', message: '', filePath: '' };
  uploadResponsePanCard = { status: '', message: '', filePath: '' };
  error: string;
  userSub: Subscription;
  saveUserSub: Subscription = null;
  user: any;

  sliderImg1Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  sliderImg2Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  sliderImg3Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  sliderImg4Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  docsImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };


  // docs
  registrationImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  aadharImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  panImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  isSellerHome = false;
  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
    private titleService: Title, private router: Router,
    private notificationService: NotificationService,
    private ordersService: OrdersService,
    public appService: AppService) { }

  ngOnInit(): void {
    this.loadScript();

    console.log(this.router.url);
    if (this.router.url === '/seller') {
      // console.log('home...');
      this.isSellerHome = true;
    }

    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        if (!this.isSellerHome) {
          this.outlet.deactivate();
        }
      }
    });

    this.titleService.setTitle('Seller Dashboard - Jhelumcart Online Pvt. Ltd.');
    this.spinner.show();
    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    this.userSub = this.usersService.getUserByUsername(username).subscribe(res => {
      this.profileLocked = res.isActive === false;
      this.licenseExpired = res.licenseExpired;
      this.subscriptionModel = res.subscriptionModel;
      this.rentPeriodInMonths = res.rentPeriodInMonths;
      this.rentAmount = res.rentAmount;
      this.user = res;

      if (!this.profileLocked) {
        this.getNofications(true);
        this.startInterval();
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });


  }

  
  public loadScript() {
    const node = document.createElement('script');
    node.src = 'https://checkout.razorpay.com/v1/checkout.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(node);
  }


  startInterval() {
    // console.log('startig');
    interval(10 * 1000)
      .pipe(
        takeUntil(this.destroyed),
        map(() => this.getNofications(false))
      ).subscribe();
  }


  getNofications(firstTime) {
    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    this.notificationService.getUnreadNotificationsCountForUser(username).subscribe(res => {
      this.appService.Data.unreadNotificationsCount = res;
      this.spinner.hide();
      const audio = new Audio('https://api.jhelumcart.com/notification.mp3');
      if (firstTime) {
        this.appService.Data.unreadNotificationsCountPrevious = res;
      }
      if (!firstTime) {
        if (this.appService.Data.unreadNotificationsCountPrevious !== this.appService.Data.unreadNotificationsCount) {
          audio.play();
          this.appService.Data.unreadNotificationsCountPrevious = this.appService.Data.unreadNotificationsCount;
        }
      }
    }, err => {
      this.spinner.hide();
    });
  }
  gotoNotifications() {
    this.router.navigate(['/seller/notifications']);
  }

  gotoProfile() {
    this.router.navigate(['/seller/profile']);
  }

  gotoChangePassword() {
    this.router.navigate(['/seller/change-password']);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/sign-in']);
  }

  navigating(path?) {


    console.log(path);
    console.log(this.router.url);
    if (path && (path === 'home')) {
      console.log('home...');
      this.isSellerHome = true;
    } else {
      this.isSellerHome = false;
    }

    this.sidenav.toggle();
  }

  saveProfile() {
    // console.log('Registration Document:', this.fileNameRegistration);
    // console.log('Pan Card:', this.fileNamePanCard);
    // console.log('Aadhar Card:', this.fileNameAadharCard);

    // return;

    // if (this.fileName1 == '' &&
    //     this.fileName2 == '' &&
    //     this.fileName3 == '' &&
    //     this.fileName4 == '') {
    //       Swal.fire('Error', 'At least one Banner Image is required.');
    //       return;
    //     }
    if (this.fileNameRegistration == '') {
      Swal.fire('Error', 'Registration Document Image is required.');
      return;
    }
    // if (this.fileNameAadharCard == '') {
    //   Swal.fire('Error', 'Aadhar Card Image is required.');
    //       return;
    // }
    // if (this.fileNamePanCard == '') {
    //   Swal.fire('Error', 'PAN Card Image is required.');
    //       return;
    // }


    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    this.user.businessLogoPath1 = this.fileName1 == '' ? '' : 'Seller/' + username + '/Logo/' + this.fileName1;
    this.user.businessLogoPath2 = this.fileName2 == '' ? '' : 'Seller/' + username + '/Logo/' + this.fileName2;
    this.user.businessLogoPath3 = this.fileName3 == '' ? '' : 'Seller/' + username + '/Logo/' + this.fileName3;
    this.user.businessLogoPath4 = this.fileName4 == '' ? '' : 'Seller/' + username + '/Logo/' + this.fileName4;
    this.user.registrationDocumentImagePath = 'Seller/' + username + '/Documents/' + this.fileNameRegistration;
    this.user.aadharCardImagePath = 'Seller/' + username + '/Documents/' + this.fileNameAadharCard;
    this.user.panCardImagePath = 'Seller/' + username + '/Documents/' + this.fileNamePanCard;
    this.user.modifiedBy = username;
    this.spinner.show();
    this.saveUserSub = this.usersService.updateUser(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'Images saved successfully.', 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    if (this.saveUserSub != null) {
      this.saveUserSub.unsubscribe();
    }
    this.destroyed.next();
  }

  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }


      this.sliderImg1Validations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.sliderImg1Validations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG" ||
        mimeType == "image/gif")) {
        this.sliderImg1Validations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 200) {
        this.sliderImg1Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg1Validations.validDimension = (width != 400 || height != 400) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg1Validations.validDimension ||
            !this.sliderImg1Validations.validSize ||
            !this.sliderImg1Validations.validType) {

            let errorMsg = "";
            if (!this.sliderImg1Validations.validDimension) {
              errorMsg += "<div>Invalid file dimensions</div>";
            }
            if (!this.sliderImg1Validations.validSize) {
              errorMsg += "<div>Invalid file size</div>";
            }
            if (!this.sliderImg1Validations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file1 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile1(formData);
          }
        };
      };
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        }
        this.fileName1 = this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage2() {
    const fileUpload = document.getElementById('fileUpload2') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.sliderImg2Validations.uploadAttempted = true;
      const file = fileUpload.files[0];
      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.sliderImg2Validations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.sliderImg2Validations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 250) {
        this.sliderImg2Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg2Validations.validDimension = (width != 1280 || height != 782) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg2Validations.validDimension ||
            !this.sliderImg2Validations.validSize ||
            !this.sliderImg2Validations.validType) {

            let errorMsg = "";
            if (!this.sliderImg2Validations.validDimension) {
              errorMsg += "<div>Invalid file dimensions</div>";
            }
            if (!this.sliderImg2Validations.validSize) {
              errorMsg += "<div>Invalid file size</div>";
            }
            if (!this.sliderImg2Validations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file2 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile2(formData);
          }
        };
      };
    };
    fileUpload.click();
  }

  private uploadFile2(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse2 = res;
          this.isInProgress2 = true;
        }
        this.fileName2 = this.file2.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }


  onClickImage3() {
    const fileUpload = document.getElementById('fileUpload3') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.sliderImg3Validations.uploadAttempted = true;
      const file = fileUpload.files[0];
      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.sliderImg3Validations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.sliderImg3Validations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 250) {
        this.sliderImg3Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg3Validations.validDimension = (width != 1280 || height != 782) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg3Validations.validDimension ||
            !this.sliderImg3Validations.validSize ||
            !this.sliderImg3Validations.validType) {

            let errorMsg = "";
            if (!this.sliderImg3Validations.validDimension) {
              errorMsg += "<div>Invalid file dimensions</div>";
            }
            if (!this.sliderImg3Validations.validSize) {
              errorMsg += "<div>Invalid file size</div>";
            }
            if (!this.sliderImg3Validations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file3 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile3(formData);
          }
        };
      };

    };
    fileUpload.click();
  }

  private uploadFile3(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse3 = res;
          this.isInProgress3 = true;
        }
        this.fileName3 = this.file3.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickImage4() {
    const fileUpload = document.getElementById('fileUpload4') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.sliderImg4Validations.uploadAttempted = true;

      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.sliderImg4Validations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.sliderImg4Validations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 250) {
        this.sliderImg4Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg4Validations.validDimension = (width != 1280 || height != 782) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg4Validations.validDimension ||
            !this.sliderImg4Validations.validSize ||
            !this.sliderImg4Validations.validType) {

            let errorMsg = "";
            if (!this.sliderImg4Validations.validDimension) {
              errorMsg += "<div>Invalid file dimensions</div>";
            }
            if (!this.sliderImg4Validations.validSize) {
              errorMsg += "<div>Invalid file size</div>";
            }
            if (!this.sliderImg4Validations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.file4 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile4(formData);
          }
        };
      };

    };
    fileUpload.click();
  }

  private uploadFile4(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse4 = res;
          this.isInProgress4 = true;
        }
        this.fileName4 = this.file4.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickRegistrationImage() {
    const fileUpload = document.getElementById('fileUploadRegistrationImage') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.registrationImgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];
      /*       if (!file.name.endsWith('.jpg') &&
              !file.name.endsWith('.JPG') &&
              !file.name.endsWith('.png') &&
              !file.name.endsWith('.PNG')) {
              Swal.fire('Error', 'Only .jpg,.JPG,.png and .PNG files allowed');
              return;
            } */
      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.registrationImgValidations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.registrationImgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 200) {
        this.registrationImgValidations.validSize = false;
      }


      if (!this.registrationImgValidations.validSize ||
        !this.registrationImgValidations.validType) {

        let errorMsg = "";
        if (!this.registrationImgValidations.validSize) {
          errorMsg += "<div>Invalid file size</div>";
        }
        if (!this.registrationImgValidations.validType) {
          errorMsg += "<div>Invalid file type</div>";
        }
        Swal.fire('Error', errorMsg, 'error');
        // console.log('returning one or more validations failed.........');
        return;
      } else {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        // console.log(formData);
        this.fileRegistration = {
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        };
        this.uploadRegistrationDocument(formData);
      }

    };
    fileUpload.click();
  }

  private uploadRegistrationDocument(formData: FormData) {
    this.usersService.uploadBusinessDocument(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponseRegistration = res;
          this.isInProgressRegistration = true;
        }
        this.fileNameRegistration = this.fileRegistration.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickAadharCardImage() {
    const fileUpload = document.getElementById('fileUploadAadharCardImage') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.aadharImgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.aadharImgValidations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.aadharImgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 200) {
        this.aadharImgValidations.validSize = false;
      }


      if (!this.aadharImgValidations.validSize ||
        !this.aadharImgValidations.validType) {

        let errorMsg = "";
        if (!this.aadharImgValidations.validSize) {
          errorMsg += "<div>Invalid file size</div>";
        }
        if (!this.aadharImgValidations.validType) {
          errorMsg += "<div>Invalid file type</div>";
        }
        Swal.fire('Error', errorMsg, 'error');
        // console.log('returning one or more validations failed.........');
        return;
      } else {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        // console.log(formData);
        this.fileAadharCard = {
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        };
        this.uploadAadharCardImage(formData);
      }


    };
    fileUpload.click();
  }

  private uploadAadharCardImage(formData: FormData) {
    this.usersService.uploadBusinessDocument(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponseAadharCard = res;
          this.isInProgressAadharCard = true;
        }
        this.fileNameAadharCard = this.fileAadharCard.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickPanCardImage() {
    const fileUpload = document.getElementById('fileUploadPanCardImage') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.panImgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.panImgValidations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.panImgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 200) {
        this.panImgValidations.validSize = false;
      }

      if (!this.panImgValidations.validSize ||
        !this.panImgValidations.validType) {

        let errorMsg = "";
        if (!this.panImgValidations.validSize) {
          errorMsg += "<div>Invalid file size</div>";
        }
        if (!this.panImgValidations.validType) {
          errorMsg += "<div>Invalid file type</div>";
        }
        Swal.fire('Error', errorMsg, 'error');
        // console.log('returning one or more validations failed.........');
        return;
      } else {
        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        // console.log(formData);
        this.filePanCard = {
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        };
        this.uploadPanCardImage(formData);
      }

    };
    fileUpload.click();
  }

  private uploadPanCardImage(formData: FormData) {
    this.usersService.uploadBusinessDocument(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponsePanCard = res;
          this.isInProgressPanCard = true;
        }
        this.fileNamePanCard = this.filePanCard.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  payNow() {
    const paymentOrder: PaymentOrder = {
      amount: this.rentAmount,
      username: JSON.parse(localStorage.getItem('token')).username,
      status: 'Payment Started'
    };
    this.spinner.show();
    this.isInProgress = true;
    this.ordersService.addPaymentOrder(paymentOrder).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const razorPaymentKey = res.razorPaymentKey;
        this.spinner.show();

        // tslint:disable-next-line:max-line-length
        this.ordersService.createRazorPaymentOrder(res.orderID, 'INR', this.rentAmount).subscribe(razorRes => {
          this.spinner.hide();
          const options = {
            key: razorPaymentKey,
            amount: this.rentAmount * 100,
            currency: 'INR',
            name: this.user.businessName,
            description: 'Subscription Charges for ' + this.rentPeriodInMonths + ' month(s)',
            image: 'https://api.jhelumcart.com/SiteSettings/Logo.png',
            order_id: razorRes.attributes.id,
            handler: (response => {
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);

              this.spinner.show();
              this.ordersService.verifyPayment(response.razorpay_order_id, response.razorpay_payment_id,
                response.razorpay_signature).subscribe(verifyRes => {
                  this.spinner.hide();
                  if (verifyRes === true) {
                    paymentOrder.status = 'Payment Successful.';
                    paymentOrder.id = res.orderID;
                    this.spinner.show();
                    // tslint:disable-next-line:max-line-length
                    this.ordersService.updatePaymentOrder(paymentOrder).subscribe(updatePaymentOrderRes => {
                      this.spinner.hide();
                      if (updatePaymentOrderRes) {

                        Swal.fire('Message', 'Payment Successful. A confirmation email has been sent to you and your license is extended now.', 'success');

                        this.isInProgress = false;
                        location.reload();
                      }
                    });
                  } else {
                    Swal.fire('Error', 'Payment not verified. It might be a hack!', 'error');
                    this.router.navigate(['/']);
                  }
                });
            }),
            prefill: {
              name: this.user.businessName,
              email: this.user.email,
              contact: this.user.mobile
            },
            notes: {
              address: this.user.businessAddress
            },
            theme: {
              color: '#F37254'
            }
          };

          const rzp1 = new Razorpay(options);
          rzp1.open();
        });
      } else {
        this.spinner.hide();
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigateByUrl('/');
      }
    });
  }

}
