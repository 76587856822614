import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProductDiscount, SeasonalDiscount } from 'src/app/models';
import { ProductDiscountService } from 'src/app/product-discount.service';
import { SeasonalDiscountService } from 'src/app/seasonal-discount.service';

@Component({
  selector: 'app-seasonal-discounts',
  templateUrl: './seasonal-discounts.component.html',
  styleUrls: ['./seasonal-discounts.component.scss']
})
export class SeasonalDiscountsComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  seasonalDiscounts: SeasonalDiscount[] = [];
  attributeId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.seasonalDiscounts);
  displayedColumns: string[] = ['couponCode', 'discountPercentage', 'from', 'to', 'actionEdit', 'actionDelete'];

  // username: string;
  // attributeID: number;
  // discountPercentage: number;
  // couponCode: string;

  constructor(
    private seasonalDiscountService: SeasonalDiscountService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.bindSeasonalDiscounts();
  }

  bindSeasonalDiscounts() {
    this.spinner.show();

    this.activatedRoute.queryParams.subscribe(params => {
      // this.attributeId = Number(atob(decodeURIComponent(params["q"])));
      this.attributeId = Number(this.activatedRoute.snapshot.params.id);

      this.seasonalDiscountService.getSeasonalDiscounts().subscribe(res => {
        console.log(res);
        this.seasonalDiscounts = res;
        this.dataSource = new MatTableDataSource<any>(this.seasonalDiscounts);
        this.dataSource.paginator = this.paginator;
        this.spinner.hide();
      }, err => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
        this.spinner.hide();
      });

    });


  }

  deleteSeasonalDiscount(id) {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete this Discount?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.seasonalDiscountService.deleteSeasonalDiscount(id).subscribe(res => {
          this.spinner.hide();
          if (res.added) {
            Swal.fire('Message', res.message, 'success');
            this.bindSeasonalDiscounts();
          } else {
            Swal.fire('Error', res.message, 'error');
          }
        }, err => {
          this.spinner.hide();
          if (err.status === 401) {
            this.router.navigateByUrl('/login');
          } else {
            Swal.fire('Error', 'Error occured. Please try again...', 'error');
          }
        });
      }
    });

  }


  navigateToEditSeasonalDiscount(discountId: number) {
    this.router.navigate([`/seller/edit-seasonal-discount/${discountId}`]);
  }

  navigateToAddDiscount() {
    this.router.navigate(['/seller/add-seasonal-discount']);
  }


}
