import { SubcategoryService } from 'src/app/subcategory.service';
import { CategoryService } from 'src/app/category.service';
import { MenuService } from 'src/app/menu.service';
import { ProductsService } from './../../products.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteSetting, SubCategory, Category, Menu, Product } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  public Editor = ClassicEditor;
  description: string;
  specifications: string;
  ckeConfig: any;

  file1: FileUploadModel;
  // saleprice
  isInProgress1 = false;
  fileName1 = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  error: string;
  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];

  constructor(private productsService: ProductsService,
    private menuService: MenuService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      //plugins: [ TableProperties, TableCellProperties],
      // table: {
      //   contentToolbar: [
      //     'tableColumn', 'tableRow', 'mergeTableCells',
      //     'tableProperties', 'tableCellProperties'
      //   ],

      //   // Configuration of the TableProperties plugin.
      //   tableProperties: {
      //     // ...
      //   },

      //   // Configuration of the TableCellProperties plugin.
      //   tableCellProperties: {
      //     // ...
      //   }
      // },
      forcePasteAsPlainText: true
    };

    this.bindMenus();
  }

  bindMenus() {
    this.spinner.show();
    this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
    });
  }

  // new FormControl('', [Validators.required, Validators.pattern('^\\d*$'),
  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      itemDescription: [''],
      shortDescription: ['', Validators.required],
      specifications: ['', Validators.required],
      description: ['', Validators.required],
      //moq: ['',[Validators.required, Validators.pattern('[1-9]{1}[0-9]*')]],
      barcode: [''],
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      codPaymentAvailable: [''],
      onlinePaymentAvailable: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }
  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file1 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile1(formData);
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        }
        this.fileName1 = this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  public onChangeDescription({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.description = data;
  }

  public onChangeSpecifications({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.specifications = data;
  }

  saveProduct() {

    const token = JSON.parse(localStorage.getItem('token'));

    this.spinner.show();
    const product: Product = new Product();
    product.name = this.form.value.name;
    product.itemDescription = this.form.value.itemDescription;
    product.shortDescription = this.form.value.shortDescription;
    product.specifications = this.specifications;
    product.description = this.description;
    product.username = token.username;
    product.isActive = this.form.value.isActive;
    product.onlinePaymentAvailable = this.form.value.onlinePaymentAvailable;
    product.codAvailable = this.form.value.codPaymentAvailable;
    product.barcode = this.form.value.barcode;
    product.categoryID = this.form.value.category;
    product.subcategoryID = this.form.value.subcategory;
    product.addedBy = JSON.parse(localStorage.getItem('token')).username

    this.productsService.addProduct(product).subscribe(res => {
      if (res.added) {
        Swal.fire('Message', res.message, 'success');
        this.router.navigate(['/seller/products']);
      } else {
        Swal.fire('Error', res.message, 'error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      if (error instanceof HttpErrorResponse) {
        if (error.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      } else {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      }      
    });
  }

}
