import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { SellerRoutingModule } from './seller-routing.module';
import { AddProductComponent } from './add-product/add-product.component';
import { AddProductAttributeComponent } from './add-product-attribute/add-product-attribute.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditProductAttributeComponent } from './edit-product-attribute/edit-product-attribute.component';
import { GenerateQrCodeComponent } from './generate-qr-code/generate-qr-code.component';
import { MessagesComponent } from './messages/messages.component';
import { OrdersComponent } from './orders/orders.component';
import { PincodesComponent } from './pincodes/pincodes.component';
import { ProductAttributesComponent } from './product-attributes/product-attributes.component';
import { ProductsComponent } from './products/products.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { SellerDashboardComponent } from './seller-dashboard/seller-dashboard.component';
import { SellerHomeComponent } from './seller-home/seller-home.component';
import { StockRegisterComponent } from './stock-register/stock-register.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StocksComponent } from './stocks/stocks.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { EditStockComponent } from './edit-stock/edit-stock.component';
import { AddBankDetailsComponent } from './add-bank-details/add-bank-details.component';
import { EditBankDetailsComponent } from './edit-bank-details/edit-bank-details.component';
import { ProductImagesComponent } from './product-images/product-images.component';
import { AddProductImageComponent } from './add-product-image/add-product-image.component';
import { EditProductImageComponent } from './edit-product-image/edit-product-image.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SlidersComponent } from './sliders/sliders.component';
import { AddSliderComponent } from './add-slider/add-slider.component';
import { EditSliderComponent } from './edit-slider/edit-slider.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { AddPincodeComponent } from './add-pincode/add-pincode.component';
import { EditPincodeComponent } from './edit-pincode/edit-pincode.component';
import { PincodeSlabsComponent } from './pincode-slabs/pincode-slabs.component';
import { AddPincodeSlabComponent } from './add-pincode-slab/add-pincode-slab.component';
import { EditPincodeSlabComponent } from './edit-pincode-slab/edit-pincode-slab.component';
import { AddProductDiscountComponent } from '../add-product-discount/add-product-discount.component';
import { ProductDiscountComponent } from '../product-discounts/product-discounts.component';
import { EditProductDiscountComponent } from '../edit-product-discount/edit-product-discount.component';
import { OWL_DATE_TIME_LOCALE, OwlNativeDateTimeModule, OwlDateTimeModule } from 'ng-pick-datetime';
import { SeasonalDiscountsComponent } from './seasonal-discounts/seasonal-discounts.component';
import { AddSeasonalDiscountComponent } from './add-seasonal-discount/add-seasonal-discount.component';
import { EditSeasonalDiscountComponent } from './edit-seasonal-discount/edit-seasonal-discount.component';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { AddCustomOrderComponent } from './add-custom-order/add-custom-order.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { SelectCustomOrderProductComponent } from './select-custom-order-product/select-custom-order-product.component';

@NgModule({
  declarations: [
    AddProductComponent,
    AddProductAttributeComponent,
    BankDetailsComponent,
    ChangePasswordComponent,
    EditProductComponent,
    EditProductAttributeComponent,
    GenerateQrCodeComponent,
    MessagesComponent,
    OrdersComponent,
    PincodesComponent,
    ProductAttributesComponent,
    ProductsComponent,
    ProfileComponent,
    ProfileEditComponent,
    SellerDashboardComponent,
    SellerHomeComponent,
    OrderDetailsComponent,
    NotificationsComponent,
    AddProductDiscountComponent,
    EditProductDiscountComponent,
    ProductDiscountComponent,
    // tslint:disable-next-line:max-line-length
    StockRegisterComponent, StocksComponent, AddStockComponent, EditStockComponent, AddBankDetailsComponent, EditBankDetailsComponent, ProductImagesComponent, AddProductImageComponent, EditProductImageComponent, SlidersComponent, AddSliderComponent, EditSliderComponent, AddPincodeComponent, EditPincodeComponent, PincodeSlabsComponent, AddPincodeSlabComponent, EditPincodeSlabComponent, SeasonalDiscountsComponent, AddSeasonalDiscountComponent, EditSeasonalDiscountComponent, CustomOrdersComponent, AddCustomOrderComponent, CustomOrderDetailsComponent, SelectCustomOrderProductComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    SellerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ColorPickerModule,
    CKEditorModule,
    NgxChartsModule,
    MatIconModule,
    MatCheckboxModule,
    NgImageFullscreenViewModule,
    FlexLayoutModule,
    NgxPaginationModule
  ],
  providers: [
    DatePipe,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ist'}
  ]
})
export class SellerModule { }
